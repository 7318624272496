import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser, logoutUser } from '../../../Common/Actions'
import { Redirect } from 'react-router-dom'
import { getToken } from '../../../Common/LocalStorage';
import jwt_decode from 'jwt-decode'
import { Link } from 'react-router-dom'
import { VERSION_DATE, VERSION_NUM } from '../../../../config';

export default function TopNavbar() {

    const dispatcher = useDispatch();
    const [redirect, setRedirect] = useState(false)
    const getUser = useSelector(state => state.auth)
    useEffect(() => {
        const tokenDetails = jwt_decode(getToken('auth'))
        dispatcher(setCurrentUser(tokenDetails))
        // eslint-disable-next-line
    }, [])

    // console.log(getUser)


    const onLogout = () => {
        dispatcher(logoutUser);
        setRedirect(true)
    }

    if (redirect) {
        return <Redirect to="/" />
    }

    return (
        <header className="app-header top-bar">

            <nav className="navbar navbar-expand-md">

                <div className="navbar-header d-flex align-items-center">
                    <Link to="/workFlow">
                        <img src="assets/img/title-logo.png" className="img-fluid logo-desktop" alt="logo" />
                        <img src="assets/img/logo-icon.png" className="img-fluid logo-mobile" alt="logo" />
                    </Link>
                    <br />
                    <span style={{ color: '#9E9FA6', textAlign: 'left', fontSize: '10px', paddingLeft: '4%' }}>Version {VERSION_NUM} <br /> {VERSION_DATE}</span>
                    <br />
                </div>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="navigation d-flex">
                        <ul className="navbar-nav nav-right ml-auto">
                            <li className="nav-item dropdown user-profile">
                                <ul className="nav-link dropdown-toggle " id="navbarDropdown4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="assets/img/ceo.png" alt="logo" style={{ width: '25px', height: '25px' }} />
                                    <span className="bg-success user-status"></span>
                                </ul>
                                <div className="dropdown-menu animated fadeIn" aria-labelledby="navbarDropdown">
                                    <div className="bg-gradient px-4 py-3">
                                        <div className="d-flex align-items-center justify-content-between">

                                            <div className="mr-1">
                                                <p className="text-white mb-0">
                                                    {getUser.user.email}
                                                </p>
                                            </div>


                                            <Link to="/" onClick={onLogout} className="text-white font-20 tooltip-wrapper" > <i
                                                className="zmdi zmdi-sign-in"></i></Link>
                                        </div>
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </nav>
        </header >
    )
}