import React, { useState, useEffect, useReducer } from 'react'
import TopNavbar from '../Utility/TopNavbar'
import SideNavbar from '../Utility/SideNavbar'
import Footer from '../Utility/Footer'
import API from '../../Common/Network/API'
import Alert from '../../Common/Alert'
import io from 'socket.io-client';
import { reducer, initialState } from './reducer'
import { getMigrationType, getSubMigrationType, getMigrationData } from './network'
import { setToken, getToken } from '../../Common/LocalStorage'
import { useDispatch, useSelector } from 'react-redux';
import { Steps } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { isLoading, isLoaded } from '../../Common/Actions/loading';
import PropertiesModal from './propertiesModal'
import UpdateModal from './updateModal'
import { API_URL, SERVER_UP_URL } from '../../../config'
import './index.css'
import RootLoader from '../../Common/Loader/RootLoader'
import Axios from 'axios'

const socket = io(API_URL);

export function Data(props) {

    const [message, setMessage] = useState()

    props.data.then(res => {
        setMessage(res)
    })


    return (
        <div>
            {
                message !== undefined ?
                    message === 200 ?
                        <div style={{ color: 'green', fontWeight: 'bold' }} >
                            Up
                        </div>
                        :
                        <div style={{ color: 'red', fontWeight: 'bold' }} >
                            Down
                        </div>

                    :
                    <div style={{ color: 'black', fontWeight: 'bold' }} >
                        Loading...
                    </div>

            }

        </div>
    )
}

export default function Migrate(props) {


    useEffect(() => {
        setToken('migrateId', props.location.state.id)
        setToken('migrateName', props.location.state.name)
    })



    const dispatcher = useDispatch();


    const loaderStatus = useSelector(state => state.loading.status)

    const isRedirect = useSelector(state => state.loading.redirect)

    const [appMigrateStatus, setAppMigrateStatus] = useState(false)
    const [dbMigrateStatus, setDbMigrateDbStatus] = useState(false)

    const [migrateId, setMigrateId] = useState()


    // application data
    const [application, setApplication] = useState({})
    // database data
    const [database, setDatabase] = useState({})
    // open migrate block
    // const [migrateFlow, setMigrateFlow] = useState(false)
    //select Main type of migration
    const [select, setSelect] = useState({})
    // select technology option
    const [technology, setTechnology] = useState(0)
    //select db option
    const [db, setDb] = useState(0)
    //migration block array
    const [migrationBlock, setMigrationBlock] = useState([])
    //count for migration blocks
    const [rows, setRows] = useState(0)
    //set properties information block
    const [properties, setProperties] = useState([])
    //socket information
    const [socketId, setSocketId] = useState()
    const [state, dispatch] = useReducer(reducer, initialState);
    const [click, setClick] = useState(false)
    const [appStatus, setAppStatus] = useState(0)
    const [dbStatus, setDbStatus] = useState(0)
    const [url, setUrl] = useState()

    const [open, setOpen] = useState(false)
    const [updateAppOpen, setUpdateAppOpen] = useState(false)
    const [updateDbOpen, setUpdateDbOpen] = useState(false)
    const [key, setKey] = useState()
    const [urlStatus, setUrlStatus] = useState()
    const [podInfo, setPodInfo] = useState()
    const [podData, setPodData] = useState([])
    const [loader, setLoader] = useState(false)
    const [port, setPort] = useState(null)
    const [alreadyOpen, setAlreadyOpen] = useState(false)
    const [appDb, setAppDb] = useState(0)
    const [viewHistory, setViewHistory] = useState(false)
    const [subMigrationTypes, setSubMigrationTypes] = useState([])

    useEffect(() => {
        fun();
        // eslint-disable-next-line
    }, {})


    const fun = () => {
        if (window.localStorage) {
            if (!localStorage.getItem('firstLoad')) {
                localStorage['firstLoad'] = true;
                window.location.reload();
            }
            else
                localStorage.removeItem('firstLoad');
        }
    }


    useEffect(() => {
        localStorage.removeItem('applicationUrl')
    }, [])


    useEffect(() => {
        var stingArray = podInfo && podInfo.replace(/  |,/gi, ",").split(',').filter(Boolean)

        setPodData(oldArray => [...oldArray, stingArray]);

    }, [podInfo])



    useEffect(() => {
        if (podData.length > 0) {
            setViewHistory(true)
        }
    }, [podData])

    const handleOpen = (i) => {
        setKey(i - 1)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdateAppClose = () => {
        setUpdateAppOpen(false);
    };

    const handleUpdateDbClose = () => {
        setUpdateDbOpen(false);
    };

    //handle select info for type of technology
    const handleTechnology = (e) => {
        setTechnology({
            ...technology,
            [e.target.name]: e.target.value
        })
    }
    //handle select info for type of database
    const handleDb = (e) => {
        setDb({
            ...db,
            [e.target.name]: e.target.value
        })
    }
    //handle select info for type of  application+database
    const handleAppDb = (e) => {
        setAppDb({
            ...appDb,
            [e.target.name]: e.target.value
        })
    }
    //handel select info for type of migration
    const handleSelect = (e) => {
        getSubMigrationType(dispatch, e.target.value, props.location.state.id)
        setSelect({
            ...select,
            [e.target.name]: e.target.value
        })
    }
    //handle to create migrate blocks with information
    const migrate = (data) => {

        // setMigrateFlow(false)
        var res = migrationBlock.map(x => x.name)
        var result = res.includes(data)
        // if (result) {
        //     Alert('success', "Already Migration Added")
        // } else {
        let arr = [...migrationBlock]
        arr.push({ 'name': data, 'type': appDb || db || technology })
        setMigrationBlock(arr)
        setRows(rows + 1)
        // }
    }

    // remove migration blocks
    const removeMigrate = () => {
        if (rows != 0)
            setRows(rows - 1)
    }
    //for socket connection
    useEffect(() => {

        getMigrationType(dispatch)

        socket.on('connection-success', success => {
            setSocketId(success.success)
            socket.emit('socked-id', success.success)
        })
    }, [])
    useEffect(() => {

        socket.on('checkSocket', message => {

            if (message.socket === socketId) {
                socket.on('appMessage', message => {
                    setClick(true)
                    handleApplicationStatus(message)
                })

                socket.on('dbMessage', message => {
                    setClick(true)
                    handleDbStatus(message)
                })
            }
        })

        // socket.on('podSocket', message => {

        //     if (message.socket === socketId) {
        //         socket.on('pod-success', message => {
        //             console.log(message, 'mymsg')
        //             if (message !== "" && message !== "undefined") {
        //                 setPodInfo(message.message)
        //             }
        //         })

        //         socket.on('pod-failure', message => {
        //             setPodInfo(message.message)
        //         })
        //     }
        // }).

        // return () => socket.disconnect();

    }, [socketId])


    useEffect(() => {
        // return () => socket.disconnect();
    }, [podInfo, click])


    useEffect(() => {
        getMigrationData(dispatch, getToken('migrateId'))
    }, [isRedirect.status])

    const handleApplicationStatus = (m) => {

        console.log(m.toString())

        if (m.toString() === '1') {
            setAppStatus(1)
        }
        if (m.toString() === '2') {
            setAppStatus(2)
        }
        if (m.toString() === '3') {
            setAppStatus(3)
        }
        if (m.toString() === '4') {
            setAppStatus(4)
        }
        if (m.toString() === '5') {
            setAppStatus(5)
            // setTimeout(() => {
            //     setAppStatus(6)
            // }, 1000)
        }

        if (getUrlFromString(m.toString()) !== undefined) {

            if (getAWSUrl(m.toString()) === 'amazonaws.com') {
                setUrl(`http://${getUrlFromString(m.toString())}`)
                localStorage.setItem('applicationUrl', getUrlFromString(m.toString()))
                localStorage.setItem('cloud', 'aws')
                localStorage.setItem('cloudUrl', getUrlFromString(m.toString()))
                API.post('/connectApi/updateUrl', { data: localStorage.getItem('migrateId'), url: getUrlFromString(m.toString()) }, {}, true);
            } else {
                if (ValidateIPaddress(m.toString())) {
                    setUrl(`http://${m.toString()}`)
                    localStorage.setItem('applicationUrl', `http://${m.toString()}`)
                    localStorage.setItem('cloud', 'GCP')
                    localStorage.setItem('cloudUrl', m.toString())
                    API.post('/connectApi/updateUrl', { data: localStorage.getItem('migrateId'), url: `http://${m.toString()}` }, {}, true);
                }
            }
        }



        if (m.toString().substring(0, 4) === 'http') {
            setUrl(m.toString())
            localStorage.setItem('applicationUrl', m.toString())
        }
        if (m.toString().substring(0, 4) === 'port') {
            setPort(m.toString())
            localStorage.setItem('applicationPort', m.toString())
        }
    }

    function getAWSUrl(words) {
        var n = words.split(" ");
        return n[n.length - 3].substr(n[n.length - 3].length - 13)
    }

    function getUrlFromString(words) {
        var n = words.split(" ");
        return n[n.length - 3]
    }

    function ValidateIPaddress(ipaddress) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }

    const handleDbStatus = (m) => {

        if (m.toString() === '1') {
            setDbStatus(1)
        }
        if (m.toString() === '2') {
            setDbStatus(2)
        }
        if (m.toString() === '3') {
            setDbStatus(3)
        }
        if (m.toString() === '4') {
            setDbStatus(4)
        }
        if (m.toString() === '5') {
            setDbStatus(5)
            setTimeout(() => {
                setDbStatus(6)
            }, 1000)
        }
    }

    // create properties values for migration blocks
    const handleProperties = (data) => {
        var value = migrationBlock.filter((x, key) => { return key + 1 === data }).map(x => { return x.name })
        let arr = [...properties]
        var name = `${value}status`
        arr.push({ [name]: true })
        setProperties(arr)
    }

    useEffect(() => {
        const migrateData = {
            destination_ip: state.destination_ip.value,
            password: state.password.value,
            port: state.port.value,
            username: state.username.value,
            vm_source_ip: state.vm_source_ip.value,
        }
        dispatcher(isLoading());
        try {

            // const response = await API.post('/connectApi/portAvailability/', {
            //     data: migrateData
            // }, {}, true);
            dispatcher(isLoaded());
        }
        catch (error) {
            console.log(error)
        }
        dispatcher(isLoaded());

    }, [state.port])


    // if (isRedirect.status) {
    //     return <Redirect to={isRedirect.path} />
    // }

    const handleMigrate = async (dispatch, data, name) => {

        if (data.migration_status === 1 && !alreadyOpen) {
            if (data.migration_type === 1) {
                setUpdateAppOpen(true)
                setAlreadyOpen(true)
            } if (data.migration_type === 2) {
                setUpdateDbOpen(true)
                setAlreadyOpen(true)
            }
            if (data.migration_type === 3) {
                if (data.sub_type === 8 || data.sub_type === 19) {
                    setUpdateAppOpen(true)
                    setAlreadyOpen(true)
                }
                if (data.sub_type === 9 || data.sub_type === 15) {
                    setUpdateDbOpen(true)
                    setAlreadyOpen(true)
                }
                if (data.sub_type === 11 || data.sub_type === 12) {
                    setUpdateDbOpen(true)
                    setAlreadyOpen(true)
                }
            }
            if (data.migration_type === 4) {
                if (data.sub_type === 17 || data.sub_type === 18) {
                    setUpdateDbOpen(true)
                    setAlreadyOpen(true)
                }
                if (data.sub_type === 16) {
                    setUpdateDbOpen(true)
                    setAlreadyOpen(true)
                }
            }
        } else {
            setMigrateId(data.migrateId)
            if (data.migration_type === 1) {
                setAppMigrateStatus(true)
            }
            if (data.migration_type === 2) {
                setDbMigrateDbStatus(true)
            }
            if (data.migration_type === 3) {
                if (data.sub_type === 8 || data.sub_type === 19) {
                    setAppMigrateStatus(true)
                }
                if (data.sub_type === 9 || data.sub_type === 15) {
                    setDbMigrateDbStatus(true)
                }
                if (data.sub_type === 11 || data.sub_type === 12) {
                    setAppMigrateStatus(true)
                }
            }
            if (data.migration_type === 4) {
                if (data.sub_type === 17 || data.sub_type === 18) {
                    setAppMigrateStatus(true)
                }
                if (data.sub_type === 16) {
                    setDbMigrateDbStatus(true)

                }
            }
            dispatch(isLoading());
            try {
                var response = await API.post('/connectApi/migrate',
                    {
                        data: data,
                        name: name,
                        socket: socketId,
                        dataBaseMigration: localStorage.getItem('dbMigrationSubType')
                    },
                    {},
                    true)
                dispatch(isLoaded());
                if (data.migration_type === 1) {
                    setAppMigrateStatus(false)
                }
                if (data.migration_type === 2) {
                    setDbMigrateDbStatus(false)
                }
                if (data.migration_type === 3) {
                    if (data.sub_type === 8 || data.sub_type === 19) {
                        setAppMigrateStatus(false)
                    }
                    if (data.sub_type === 9 || data.sub_type === 15) {
                        setDbMigrateDbStatus(false)
                    }
                    if (data.sub_type === 11 || data.sub_type === 12) {
                        setAppMigrateStatus(false)
                    }
                }
                if (data.migration_type === 4) {
                    if (data.sub_type === 17 || data.sub_type === 18) {
                        setAppMigrateStatus(false)
                    }
                    if (data.sub_type === 16) {
                        setDbMigrateDbStatus(false)
                    }
                }
            } catch (error) {
                Alert("error", error.message)
            }
            dispatch(isLoaded());
            if (data.migration_type === 1) {
                setAppMigrateStatus(false)
            }
            if (data.migration_type === 2) {
                setDbMigrateDbStatus(false)
            }
            if (data.migration_type === 3) {
                if (data.sub_type === 8 || data.sub_type === 19) {
                    setAppMigrateStatus(false)
                }
                if (data.sub_type === 9) {
                    setDbMigrateDbStatus(false)
                }
                if (data.sub_type === 11 || data.sub_type === 12) {
                    setAppMigrateStatus(false)
                }
            }
            if (data.migration_type === 4) {
                if (data.sub_type === 17 || data.sub_type === 18) {
                    setAppMigrateStatus(false)
                }
                if (data.sub_type === 16) {
                    setDbMigrateDbStatus(false)
                }
            }
        }
    }

    useEffect(async () => {
        await API.get('/dataApi/getAllSubMigrations', {}, {}, true).then(res => {
            setSubMigrationTypes(res)
        })
    }, [])

    const getServerMigrationObject = (data) => {
        console.log(([data].filter(function (e) {
            return e.sub_type === 17
        }))[0], 'sdfasfasd')

        return [data].filter(function (e) {
            return e.sub_type === 17
        })[0] || {}
    }
    const getApplicationMigrationObject = (data) => {
        console.log(([data].filter(function (e) {
            return e.sub_type === 18
        }))[0], 'sdfasfasd')

        return [data].filter(function (e) {
            return e.sub_type === 18
        })[0] || {}
    }

    var Application = [];
    var Database = [];
    //if migrated blocks are there  it will show

    if (state.migrationData != '') {
        state.migrationData.map((migrate, index) => {



            migrate.migration_type === 1 &&
                (
                    Application.push(<div key={index} className="container-left ml-3" style={{ width: "25rem" }}>
                        <div className="card card-statistics overflow-h">
                            <div className="card-body p-0">
                                <div className="d-flex m-b-0 contant-text h-100">
                                    <div className="w-100">
                                        <div className="row p-3">
                                            <div className="col">
                                                {/* <h5 className="mb-0">{props.location.state.name}</h5> */}
                                                <h5 className="mb-0">Application: {migrate.name}</h5>
                                            </div>
                                            <div className="col text-right">
                                                {
                                                    migrate.test_con_status === 1 ?
                                                        <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                        :
                                                        <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                }
                                            </div>
                                        </div>
                                        <div className="accordion" id="accordionsimplefill">
                                            <div className="acd-group">
                                                <div className="card-header rounded-0">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link"
                                                            onClick={() => setUpdateAppOpen(true)} >Update Properties</button>
                                                    </h5>
                                                </div>
                                                {
                                                    updateAppOpen ? <UpdateModal open={updateAppOpen}
                                                        handleClose={handleUpdateAppClose} type="application" work_flow_id={props.location.state.id} migrate={migrate} /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div >)
                )
            migrate.migration_type === 2 &&
                (
                    Database.push(<div className="ml-3 container-left" style={{ width: "25rem" }}>
                        <div className="card card-statistics overflow-h">
                            <div className="card-body p-0">
                                <div className="d-flex m-b-0 contant-text h-100">
                                    <div className="w-100">
                                        <div className="row p-3">
                                            <div className="col">
                                                {/* <h5 className="mb-0">{props.location.state.name}</h5> */}
                                                <h5 className="mb-0">Database: {migrate.name}</h5>
                                            </div>
                                            <div className="col text-right">
                                                {
                                                    migrate.test_con_status === 1 ?
                                                        <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                        :
                                                        <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                }
                                            </div>
                                        </div>
                                        <div className="accordion" id="accordionsimplefill">
                                            <div className="acd-group">
                                                <div className="card-header rounded-0">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link"
                                                            onClick={() => setUpdateDbOpen(true)} >Update Properties</button>

                                                    </h5>
                                                </div>
                                                {
                                                    updateDbOpen ? <UpdateModal open={updateDbOpen}
                                                        handleClose={handleUpdateDbClose} type="database" work_flow_id={props.location.state.id} migrate={migrate} /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >)
                )
            migrate.migration_type === 3 && (

                (migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19) ? (
                    Application.push(<div key={index} className="container-left ml-3" style={{ width: "25rem" }}>
                        <div className="card card-statistics overflow-h">
                            <div className="card-body p-0">
                                <div className="d-flex m-b-0 contant-text h-100">
                                    <div className="w-100">
                                        <div className="row p-3">
                                            <div className="col">
                                                {/* <h5 className="mb-0">{props.location.state.name}</h5> */}
                                                <h5 className="mb-0">Application: {migrate.name}</h5>
                                            </div>
                                            <div className="col text-right">
                                                {
                                                    migrate.test_con_status === 1 ?
                                                        <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                        :
                                                        <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                }
                                            </div>
                                        </div>
                                        <div className="accordion" id="accordionsimplefill">
                                            <div className="acd-group">
                                                <div className="card-header rounded-0">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link"
                                                            onClick={() => setUpdateAppOpen(true)} >Update Properties</button>
                                                    </h5>
                                                </div>
                                                {
                                                    updateAppOpen ? <UpdateModal open={updateAppOpen}
                                                        handleClose={handleUpdateAppClose} type="application" work_flow_id={props.location.state.id} migrate={migrate} /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div >)
                )
                    :
                    (migrate.sub_type === 9 || migrate.sub_type === 15) && (
                        Database.push(<div key={index} className="ml-3 container-left" style={{ width: "25rem" }}>
                            <div className="card card-statistics overflow-h">
                                {localStorage.setItem('dbMigrationSubType', migrate.sub_type)}
                                <div className="card-body p-0">
                                    <div className="d-flex m-b-0 contant-text h-100">
                                        <div className="w-100">
                                            <div className="row p-3">
                                                <div className="col">
                                                    {/* <h5 className="mb-0">{props.location.state.name}</h5> */}
                                                    <h5 className="mb-0">Database: {migrate.name}</h5>
                                                </div>
                                                <div className="col text-right">
                                                    {
                                                        migrate.test_con_status === 1 ?
                                                            <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                            :
                                                            <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                    }
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordionsimplefill">
                                                <div className="acd-group">
                                                    <div className="card-header rounded-0">
                                                        <h5 className="mb-0">
                                                            <button className="btn btn-link"
                                                                onClick={() => setUpdateDbOpen(true)} >Update Properties</button>

                                                        </h5>
                                                    </div>
                                                    {
                                                        updateDbOpen ? <UpdateModal open={updateDbOpen}
                                                            handleClose={handleUpdateDbClose} type="database" work_flow_id={props.location.state.id} migrate={migrate} /> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div >)
                    )
            )
            migrate.migration_type === 4 && (

                migrate.sub_type === 17 && (

                    Application.push(<div key={index} className="container-left ml-3" style={{ width: "25rem" }}>

                        <div className="card card-statistics overflow-h">
                            <div className="card-body p-0">
                                <div className="d-flex m-b-0 contant-text h-100">
                                    <div className="w-100">
                                        <div className="row p-3">
                                            <div className="col">
                                                {/* <h5 className="mb-0">{props.location.state.name}</h5> */}
                                                <h5 className="mb-0">Application: {migrate.name}</h5>
                                            </div>
                                            <div className="col text-right">
                                                {
                                                    migrate.test_con_status === 1 ?
                                                        <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                        :
                                                        <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                }
                                            </div>
                                        </div>
                                        <div className="accordion" id="accordionsimplefill">
                                            <div className="acd-group">
                                                <div className="card-header rounded-0">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link"
                                                            onClick={() => setUpdateAppOpen(true)} >Update Properties</button>
                                                    </h5>
                                                </div>

                                                {
                                                    updateAppOpen ? <UpdateModal open={updateAppOpen}
                                                        handleClose={handleUpdateAppClose} type="application" sub_type="server" work_flow_id={props.location.state.id} migrate={getServerMigrationObject(migrate)} /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div >)
                )
            )
            migrate.migration_type === 4 && (

                migrate.sub_type === 18 && (

                    Application.push(<div key={index} className="container-left ml-3" style={{ width: "25rem" }}>

                        <div className="card card-statistics overflow-h">
                            <div className="card-body p-0">
                                <div className="d-flex m-b-0 contant-text h-100">
                                    <div className="w-100">
                                        <div className="row p-3">
                                            <div className="col">
                                                {/* <h5 className="mb-0">{props.location.state.name}</h5> */}
                                                <h5 className="mb-0">Application: {migrate.name}</h5>
                                            </div>
                                            <div className="col text-right">
                                                {
                                                    migrate.test_con_status === 1 ?
                                                        <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                        :
                                                        <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                }
                                            </div>
                                        </div>
                                        <div className="accordion" id="accordionsimplefill">
                                            <div className="acd-group">
                                                <div className="card-header rounded-0">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link"
                                                            onClick={() => setUpdateAppOpen(true)} >Update Properties</button>
                                                    </h5>
                                                </div>

                                                {
                                                    updateAppOpen ? <UpdateModal open={updateAppOpen}
                                                        handleClose={handleUpdateAppClose} type="application" sub_type="server" work_flow_id={props.location.state.id} migrate={getApplicationMigrationObject(migrate)} /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div >)
                )
            )
            migrate.migration_type === 4 && (
                migrate.sub_type === 16 && (
                    Database.push(<div key={index} className="ml-3 container-left" style={{ width: "25rem" }}>
                        <div className="card card-statistics overflow-h">
                            {localStorage.setItem('dbMigrationSubType', migrate.sub_type)}
                            <div className="card-body p-0">
                                <div className="d-flex m-b-0 contant-text h-100">
                                    <div className="w-100">
                                        <div className="row p-3">
                                            <div className="col">
                                                {/* <h5 className="mb-0">{props.location.state.name}</h5> */}
                                                <h5 className="mb-0">Database: {migrate.name}</h5>
                                            </div>
                                            <div className="col text-right">
                                                {
                                                    migrate.test_con_status === 1 ?
                                                        <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                        :
                                                        <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                                }
                                            </div>
                                        </div>
                                        <div className="accordion" id="accordionsimplefill">
                                            <div className="acd-group">
                                                <div className="card-header rounded-0">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link"
                                                            onClick={() => setUpdateDbOpen(true)} >Update Properties</button>

                                                    </h5>
                                                </div>
                                                {
                                                    updateDbOpen ? <UpdateModal open={updateDbOpen}
                                                        handleClose={handleUpdateDbClose} type="database" work_flow_id={props.location.state.id} migrate={migrate} /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >)
                )
            )
        }
        )
    }

    var elements = [];


    // generation of multiple blocks
    for (var i = 0; i < rows; i++) {
        elements.push(
            <div className="container-left ml-3" style={{ width: "25rem" }} >
                <div className="card card-statistics overflow-h">
                    <div className="card-body p-0">
                        <div className="d-flex m-b-0 contant-text h-100">
                            <div className="w-100">
                                <div className="row p-3">
                                    <div className="col">
                                        <b style={{ fontSize: '14px', fontWeight: '500' }} className="mb-0">{`${migrationBlock[i].name}`}</b>
                                    </div>
                                    <div className="col text-right">
                                        <strong className="text-danger m-t-5" onClick={() => removeMigrate()}><i className="zmdi zmdi-minus-circle-outline font-weight-bold"></i> Remove</strong>
                                    </div>
                                </div>
                                <div>
                                    <div className="accordion" id="accordionsimplefill">
                                        <div className="acd-group">
                                            <div className="card-header rounded-0">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link"
                                                        onClick={() => handleOpen(i)} >Set Properties</button>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        open ? <PropertiesModal open={open}
                                            handleClose={handleClose} i={i} migrationBlock={migrationBlock[i].name} migration_type={migrationBlock[i].type} select={select} technology={technology} db={db} appDd={appDb} work_flow_id={props.location.state.id} app={props.location.state.name} removeMigrate={removeMigrate} /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    const getPing = async (url) => {
        try {
            const response = await Axios.post(`${SERVER_UP_URL}/pingApi/pingStatus`, { url: url })
                .then(res => {
                    if (res.data.message === 200) {
                        setAppStatus(6)
                    }
                    return res.data
                })
                .catch(err => {
                    return err.data
                })

            return response.message
        }
        catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (urlStatus === 200) {
            setAppStatus(6)
        }
    }, [urlStatus])


    // useEffect(() => {
    //     state.migrationData && state.migrationData.map(x => {
    //         return handleGetPodInfo(x)
    //     })

    // }, [state.migrationData])

    // useEffect(() => {
    //     if (podInfo === undefined) {
    //         state.migrationData && state.migrationData.map(x => {
    //             return handleGetPodInfo(x)
    //         })
    //     }
    // }, [podInfo])


    useEffect(() => {
        var portInfo = url && url.slice(url.length - 5)
        var portInfo2 = port !== null && port.slice(port.length - 5)
        if (port === null) {
            url && API.post('/connectApi/updatePort', { data: migrateId, port: portInfo }, {}, true);
        } else {
            url && API.post('/connectApi/updatePort', { data: migrateId, port: portInfo2 }, {}, true);
        }

    }, [url, port])


    // const handleGetPodInfo = async (x) => {
    //     setLoader(true)
    //     try {
    //         await API.post('/connectApi/getPods', { data: x, socket: socketId }, {}, true);
    //         setLoader(false)
    //     }
    //     catch (error) {
    //         console.log(error.message)
    //         setLoader(false)
    //     }
    //     setLoader(false)
    // }


    return (
        <div>
            <TopNavbar />
            <div className="app-container">
                <SideNavbar />
                <div className="app-main" id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 m-b-30">

                                <div className="d-block d-sm-flex flex-nowrap align-items-center">
                                    <div className="page-title mb-2 mb-sm-0">
                                        <h4>{props.location.state.name.charAt(0).toUpperCase() + props.location.state.name.slice(1)} Work Flow</h4>
                                    </div>
                                    <div className="ml-auto d-flex align-items-center">
                                        <nav>
                                            <ol className="breadcrumb p-0 m-b-0">
                                                <li className="breadcrumb-item">
                                                    <a href="index.html"><i className="ti ti-home"></i></a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    Migration
                                                </li>
                                                {/* <li className="breadcrumb-item active text-primary" aria-current="page">Migration</li> */}
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="container-left ml-3" style={{ width: "20rem" }}>

                                <div className="card card-statistics overflow-h">
                                    <div className="card-body p-0">
                                        <div className="d-flex m-b-0 contant-text h-100">
                                            <div className="w-100">
                                                <div className="row p-3">
                                                    <div className="col">
                                                        <h5 className="mb-1"><i className="nav-icon ti ti-plus"></i> Migration Block
                                                        </h5>
                                                        <div>
                                                            <div className="card-body">
                                                                <small className="d-block">Select Type of Migration</small>
                                                                <div className="form-group mb-0">
                                                                    <select name="migrationType" onChange={(e) => handleSelect(e)} className="js-basic-single form-control">
                                                                        {state.migrationType.map((migrationType, index) =>
                                                                            <option key={index} value={index}>{migrationType.name}</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                                {
                                                                    select.migrationType ?
                                                                        <div>
                                                                            {
                                                                                select.migrationType === '1' && (
                                                                                    <div>
                                                                                        <small className="d-block">Select Technology</small>
                                                                                        <div className="form-group mb-0 mt-1">
                                                                                            <select name="technology" onChange={(e) => handleTechnology(e)} className="js-basic-single form-control">
                                                                                                <option value="0">Select One</option>
                                                                                                {state.subMigrationType.map((subMigrationType, index) =>
                                                                                                    <option key={index} value={subMigrationType.id}>{subMigrationType.name}</option>)
                                                                                                }

                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {

                                                                                select.migrationType === "2" && (
                                                                                    <div>
                                                                                        <small className="d-block">Select Database</small>
                                                                                        <div className="form-group mb-0 mt-1">
                                                                                            <select name="db" onChange={(e) => handleDb(e)} className="js-basic-single form-control">
                                                                                                <option value="0">Select One</option>
                                                                                                {state.subMigrationType.map((subMigrationType, index) =>
                                                                                                    <option key={index} value={subMigrationType.id}>{subMigrationType.name}</option>)
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            {

                                                                                select.migrationType === "3" && (
                                                                                    <div>
                                                                                        <small className="d-block">Select One</small>
                                                                                        <div className="form-group mb-0 mt-1">
                                                                                            <select name="appDb" onChange={(e) => handleAppDb(e)} className="js-basic-single form-control">
                                                                                                <option value="0">Select One</option>
                                                                                                {state.subMigrationType.map((subMigrationType, index) =>
                                                                                                    <option key={index} value={subMigrationType.id}>{subMigrationType.name}</option>)
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {

                                                                                select.migrationType === "4" && (
                                                                                    <div>
                                                                                        <small className="d-block">Select One</small>
                                                                                        <div className="form-group mb-0 mt-1">
                                                                                            <select name="appDb" onChange={(e) => handleAppDb(e)} className="js-basic-single form-control">
                                                                                                <option value="0">Select One</option>
                                                                                                {state.subMigrationType.map((subMigrationType, index) =>
                                                                                                    <option key={index} value={subMigrationType.id}>{subMigrationType.name}</option>)
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                            <div className='mt-2'>
                                                                                <button
                                                                                    disabled={db !== 0 || technology !== 0 || appDb !== 0 ? false : true}
                                                                                    onClick={() => migrate(state.migrationType[select.migrationType].name)} className="btn btn-primary btn-block">Add</button>
                                                                            </div>
                                                                        </div>
                                                                        :

                                                                        null
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {elements}
                            {Application}
                            {Database}
                        </div>
                        {state.migrationData != '' ?
                            <div className="row">
                                <div className="col-xxl-12 m-b-30">
                                    <div className="card card-statistics h-100 mb-0">
                                        <div className="card-header d-flex align-items-center justify-content-between">
                                            <div className="card-heading">
                                                <h5 className="card-title">{`Ready to Migration (${props.location.state.name})`}</h5>
                                            </div>
                                            <div className="dropdown">
                                                {/* <a className="btn btn-xs" href="#!">Export <i className="zmdi zmdi-download pl-1"></i> </a> */}
                                            </div>
                                        </div>
                                        <div className="card-body scrollbar scroll_dark pt-0" >
                                            <div className="datatable-wrapper table-responsive">
                                                <table id="" className="table table-borderless table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            state.migrationData != '' ? state.migrationData.map((migrate, index) => {
                                                                return (
                                                                    state.migrationData && state.migrationData.length > 0 ?
                                                                        <tr key={index}>
                                                                            <td>{(index + 1)}</td>
                                                                            <td>{migrate.name}{migrate.migration_type == "1" && "- Application" || migrate.migration_type == "2" && "- Database"}</td>
                                                                            <td>
                                                                                {
                                                                                    <div style={{ marginTop: '20px' }}>
                                                                                        {
                                                                                            migrate.migration_type === 1 && (
                                                                                                <Steps current={appStatus} currentStatus="">
                                                                                                    <Steps.Item title={appStatus === 1 ? "" : ""} description="Build" />
                                                                                                    <Steps.Item title={appStatus === 2 ? "" : ""} description="Register" />
                                                                                                    <Steps.Item title={appStatus === 3 ? "" : ""} description="Create" />
                                                                                                    <Steps.Item title={appStatus === 4 ? "" : ""} description="Deploy" />
                                                                                                    <Steps.Item title={appStatus === 5 ? "" : ""} description="Migrate" />
                                                                                                    <Steps.Item title={appStatus === 6 ? "" : ""} description="Ready" />
                                                                                                </Steps>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            migrate.migration_type === 2 && (
                                                                                                <Steps current={dbStatus} currentStatus="">
                                                                                                    <Steps.Item title={dbStatus === 1 ? "" : ""} description="Build" />
                                                                                                    <Steps.Item title={dbStatus === 2 ? "" : ""} description="Register" />
                                                                                                    <Steps.Item title={dbStatus === 3 ? "" : ""} description="Create" />
                                                                                                    <Steps.Item title={dbStatus === 4 ? "" : ""} description="Deploy" />
                                                                                                    <Steps.Item title={dbStatus === 5 ? "" : ""} description="Migrate" />
                                                                                                    <Steps.Item title={dbStatus === 6 ? "" : ""} description="Ready" />
                                                                                                </Steps>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            migrate.migration_type === 3 && (
                                                                                                <Steps current={migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19 ? appStatus : dbStatus} currentStatus="">
                                                                                                    <Steps.Item title={migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19 ? appStatus === 1 : dbStatus === 1 ? "" : ""} description="Build" />
                                                                                                    <Steps.Item title={migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19 ? appStatus === 2 : dbStatus === 2 ? "" : ""} description="Register" />
                                                                                                    <Steps.Item title={migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19 ? appStatus === 3 : dbStatus === 3 ? "" : ""} description="Create" />
                                                                                                    <Steps.Item title={migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19 ? appStatus === 4 : dbStatus === 4 ? "" : ""} description="Deploy" />
                                                                                                    <Steps.Item title={migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19 ? appStatus === 5 : dbStatus === 5 ? "" : ""} description="Migrate" />
                                                                                                    <Steps.Item title={migrate.sub_type === 8 || migrate.sub_type === 11 || migrate.sub_type === 12 || migrate.sub_type === 19 ? appStatus === 6 : dbStatus === 6 ? "" : ""} description="Ready" />
                                                                                                </Steps>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            migrate.migration_type === 4 && (
                                                                                                <Steps current={migrate.sub_type === 16 || migrate.sub_type === 17 || migrate.sub_type === 18 ? appStatus : dbStatus} currentStatus="">
                                                                                                    <Steps.Item title={migrate.sub_type === 16 || migrate.sub_type === 17 || migrate.sub_type === 18 ? appStatus === 1 : dbStatus === 1 ? "" : ""} description="Build" />
                                                                                                    <Steps.Item title={migrate.sub_type === 16 || migrate.sub_type === 17 || migrate.sub_type === 18 ? appStatus === 2 : dbStatus === 2 ? "" : ""} description="Register" />
                                                                                                    <Steps.Item title={migrate.sub_type === 16 || migrate.sub_type === 17 || migrate.sub_type === 18 ? appStatus === 3 : dbStatus === 3 ? "" : ""} description="Create" />
                                                                                                    <Steps.Item title={migrate.sub_type === 16 || migrate.sub_type === 17 || migrate.sub_type === 18 ? appStatus === 4 : dbStatus === 4 ? "" : ""} description="Deploy" />
                                                                                                    <Steps.Item title={migrate.sub_type === 16 || migrate.sub_type === 17 || migrate.sub_type === 18 ? appStatus === 5 : dbStatus === 5 ? "" : ""} description="Migrate" />
                                                                                                    <Steps.Item title={migrate.sub_type === 16 || migrate.sub_type === 17 || migrate.sub_type === 18 ? appStatus === 6 : dbStatus === 6 ? "" : ""} description="Ready" />
                                                                                                </Steps>
                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    migrate.migration_type === 1 && (
                                                                                        <div className="row action-button">
                                                                                            <div className="col-12">
                                                                                                <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{appMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>
                                                                                            </div>
                                                                                            {url && localStorage.getItem('applicationUrl') !== "" ?
                                                                                                <div className="row col-12">
                                                                                                    {
                                                                                                        localStorage.getItem("cloud") === 'GCP' ?
                                                                                                            <div className="col-2">
                                                                                                                <Data data={getPing(localStorage.getItem('cloudUrl'))} url={localStorage.getItem('cloudUrl')} />
                                                                                                            </div>
                                                                                                            :
                                                                                                            localStorage.getItem("cloud") === 'aws' ?
                                                                                                                <div className="col-2">
                                                                                                                    <Data data={getPing(localStorage.getItem('cloudUrl'))} url={localStorage.getItem('cloudUrl')} />
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="col-2">
                                                                                                                    <Data data={getPing(url || localStorage.getItem('applicationUrl'))} url={url || localStorage.getItem('applicationUrl')} />
                                                                                                                </div>
                                                                                                    }

                                                                                                    <div className="col-2">
                                                                                                        <a className="p-1" title={url || localStorage.getItem("cloud") === 'aws' ? localStorage.getItem('cloudUrl') : localStorage.getItem('applicationUrl')} href={url} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>

                                                                                    )
                                                                                }
                                                                                {
                                                                                    migrate.migration_type === 2 && (
                                                                                        <div className="row action-button">
                                                                                            <div className="col-12">
                                                                                                <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{dbMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>

                                                                                            </div>
                                                                                            {dbStatus === 6 ?
                                                                                                <div className="row col-12">
                                                                                                    <div className="col-2" style={{ color: 'green' }}>
                                                                                                        Up
                                                                                                    </div>
                                                                                                    <div className="col-2">
                                                                                                        {/* <a className="p-1" href={url} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                    )

                                                                                }
                                                                                {
                                                                                    migrate.migration_type === 3 && (
                                                                                        migrate.sub_type === 8 || migrate.sub_type === 19 ? (
                                                                                            <div className="row action-button">
                                                                                                <div className="col-12">
                                                                                                    <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{appMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>
                                                                                                </div>
                                                                                                {url && localStorage.getItem('applicationUrl') !== "" ?
                                                                                                    <div className="row col-12">
                                                                                                        <div className="col-2">
                                                                                                            <Data data={getPing(url || localStorage.getItem('applicationUrl'))} url={url || localStorage.getItem('applicationUrl')} />
                                                                                                        </div>
                                                                                                        <div className="col-2">
                                                                                                            <a className="p-1" href={url || localStorage.getItem('applicationUrl')} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                            :
                                                                                            migrate.sub_type === 9 ? (
                                                                                                <div className="row action-button">
                                                                                                    <div className="col-12">
                                                                                                        <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{dbMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>

                                                                                                    </div>
                                                                                                    {dbStatus === 6 ?
                                                                                                        <div className="row col-12">
                                                                                                            <div className="col-2" style={{ color: 'green' }}>
                                                                                                                Up
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                {/* <a className="p-1" href={url} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                                :
                                                                                                migrate.sub_type === 11 ? (
                                                                                                    <div className="row action-button">
                                                                                                        <div className="col-12">
                                                                                                            <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{appMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>
                                                                                                        </div>
                                                                                                        {url && localStorage.getItem('applicationUrl') !== "" ?
                                                                                                            <div className="row col-12">
                                                                                                                <div className="col-2">
                                                                                                                    <Data data={getPing(url || localStorage.getItem('applicationUrl'))} url={url || localStorage.getItem('applicationUrl')} />
                                                                                                                </div>
                                                                                                                <div className="col-2">
                                                                                                                    <a className="p-1" href={url || localStorage.getItem('applicationUrl')} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                                    :
                                                                                                    migrate.sub_type === 12 ? (
                                                                                                        <div className="row action-button">
                                                                                                            <div className="col-12">
                                                                                                                <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{appMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>
                                                                                                            </div>
                                                                                                            {url && localStorage.getItem('applicationUrl') !== "" ?
                                                                                                                <div className="row col-12">
                                                                                                                    <div className="col-2">
                                                                                                                        <Data data={getPing(url || localStorage.getItem('applicationUrl'))} url={url || localStorage.getItem('applicationUrl')} />
                                                                                                                    </div>
                                                                                                                    <div className="col-2">
                                                                                                                        <a className="p-1" href={url || localStorage.getItem('applicationUrl')} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                null
                                                                                                            }
                                                                                                        </div>
                                                                                                    )
                                                                                                        :
                                                                                                        migrate.sub_type === 15 ? (
                                                                                                            <div className="row action-button">
                                                                                                                <div className="col-12">
                                                                                                                    <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{dbMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>

                                                                                                                </div>
                                                                                                                {dbStatus === 6 ?
                                                                                                                    <div className="row col-12">
                                                                                                                        <div className="col-2" style={{ color: 'green' }}>
                                                                                                                            Up
                                                                                                                        </div>
                                                                                                                        <div className="col-2">
                                                                                                                            {/* <a className="p-1" href={url} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a> */}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    null
                                                                                                                }
                                                                                                            </div>
                                                                                                        ) :
                                                                                                            null
                                                                                    )

                                                                                }
                                                                                {
                                                                                    migrate.migration_type === 4 && (

                                                                                        migrate.sub_type === 16 ? (
                                                                                            <div className="row action-button">
                                                                                                <div className="col-12">
                                                                                                    <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{dbMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>

                                                                                                </div>
                                                                                                {dbStatus === 6 ?
                                                                                                    <div className="row col-12">
                                                                                                        <div className="col-2" style={{ color: 'green' }}>
                                                                                                            Up
                                                                                                        </div>
                                                                                                        <div className="col-2">
                                                                                                            {/* <a className="p-1" href={url} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                            :
                                                                                            migrate.sub_type === 17 ? (
                                                                                                <div className="row action-button">
                                                                                                    <div className="col-12">
                                                                                                        <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{appMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>
                                                                                                    </div>
                                                                                                    {url && localStorage.getItem('applicationUrl') !== "" ?
                                                                                                        <div className="row col-12">
                                                                                                            <div className="col-2">
                                                                                                                <Data data={getPing(url || localStorage.getItem('applicationUrl'))} url={url || localStorage.getItem('applicationUrl')} />
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                <a className="p-1" href={url || localStorage.getItem('applicationUrl')} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        null
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                                :
                                                                                                migrate.sub_type === 18 ? (
                                                                                                    <div className="row action-button">
                                                                                                        <div className="col-12">
                                                                                                            <button type="button" onClick={() => handleMigrate(dispatcher, migrate, getToken('migrateName'))} className="btn btn-xs btn-primary">{appMigrateStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : migrate.migration_status === 1 ? "Remigrate" : "Migrate"}</button>
                                                                                                        </div>
                                                                                                        {url && localStorage.getItem('applicationUrl') !== "" ?
                                                                                                            <div className="row col-12">
                                                                                                                <div className="col-2">
                                                                                                                    <Data data={getPing(url || localStorage.getItem('applicationUrl'))} url={url || localStorage.getItem('applicationUrl')} />
                                                                                                                </div>
                                                                                                                <div className="col-2">
                                                                                                                    <a className="p-1" href={url || localStorage.getItem('applicationUrl')} target="_blank"><i title={url} className="zmdi zmdi-link" style={{ fontSize: '20px' }}></i></a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                                    :

                                                                                                    null
                                                                                    )

                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        "Loading...."

                                                                )
                                                            }) : null
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div> : ""
                        }
                        {/* {
                            viewHistory && (
                                <div className="row">
                                    <div className="col-xxl-12 m-b-30">
                                        <div className="card card-statistics h-100 mb-0">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <div className="card-heading">
                                                    <h5 className="card-title">Migration History</h5>
                                                </div>
                                            </div>
                                            <div className="card-body scrollbar scroll_dark pt-0" >
                                                {loader ? <div className="loader"><RootLoader /></div> : null}
                                            </div>

                                            <div>
                                                <div className="card-body scrollbar scroll_dark pt-0" >
                                                    <div className="datatable-wrapper table-responsive">
                                                        <table id="" className="table table-borderless table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Ready</th>
                                                                    <th>Status</th>
                                                                    <th>Restarts</th>
                                                                    <th>Age</th>
                                                                    <th>Worker Node</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {

                                                                    podData.filter(Boolean).map(x => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{x[0]}</td>
                                                                                <td>{x[1]}</td>
                                                                                <td>{x[2]}</td>
                                                                                <td>{x[3]}</td>
                                                                                <td>{x[4]}</td>
                                                                                <td>{x[6]}</td>
                                                                            </tr>
                                                                        )
                                                                    })

                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                            </div>



                                        </div>

                                    </div>
                                </div>
                            )
                        } */}
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}
