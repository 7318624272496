import { IS_LOADED, IS_LOADING, IS_REDIRECT } from "../Actions/actions"

const loadingStatus = {
    status: false,
    redirect: {
        status: false,
        path: ''
    }
}

export function loadingReducer(state = loadingStatus, action) {
    switch (action.type) {
        case IS_LOADING:
            return { ...state, status: true }
        case IS_LOADED:
            return { ...state, status: false }
        case IS_REDIRECT:
            return {
                ...state, redirect: {
                    ...state.redirect,
                    status: true,
                    path: action.payLoad
                }
            }
        default:
            return { ...state }
    }

}
