import React from 'react'
import { Link } from 'react-router-dom'
import { getToken } from '../../../Common/LocalStorage'

export default function SideNavbar() {

    return (
        <aside className="app-navbar">

            <div className="sidebar-nav scrollbar scroll_light">
                <ul className="metismenu " id="sidebarNav">
                    <li className="active">
                        <Link to="/dashboard">
                            <i className="nav-icon ti ti-dashboard"></i>
                            <span className="nav-title">Dashboard</span>
                        </Link>
                    </li>
                    <li className="active"> <Link to='/workFlow' >
                        <i className="nav-icon ti ti-panel"></i>
                        <span className="nav-title">WorkFlow</span>
                    </Link></li>
                    {/* {
                        getToken('migrateId') != null && getToken('migrateName') != null ?
                            <li className="active"> <Link to={{
                                pathname: '/migrate', state: {
                                    id: getToken('migrateId'),
                                    name: getToken('migrateName')
                                }
                            }}>
                                <i className="nav-icon ti ti-package"></i>
                                <span className="nav-title">Migrations</span>
                            </Link></li>
                            :
                            ""

                    } */}

                </ul>
            </div>
        </aside>
    )
}