import * as actions from './actions'
export const initialState = {
    workFlow: [],
    message: ""
};


export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_WORK_FLOWS:
            return { ...state, workFlow: action.payload };
        case actions.MESSAGE:
            return { ...state, message: action.payload }
        default:
            return state;
    }
}