import API from "../../Common/Network/API";

export const GET_WORK_FLOWS = 'GET_WORK_FLOWS'
export const MESSAGE = 'MESSAGE'


export async function getWorkFlow(dispatch) {
    try {
        return await API.get(
            "/workFlow/getRecentWorkFlows",
            true
        ).then(response => {
            dispatch({
                type: GET_WORK_FLOWS,
                payload: response
            });
        });
    } catch (err) {
        dispatch({
            type: GET_WORK_FLOWS,
            payload: err.message
        });
    }
};

export function Message(data) {
    return {
        type: MESSAGE,
        payload: data
    }
}