export const API_URL = 'https://vm2capi.novisync.com/'
export const VERSION_NUM = '#4.7.1'
export const VERSION_DATE = '12-18-2021'
// export const API_URL = "http://localhost:31164/"
export const POD_URL = "http://localhost:31153/"
export const SERVER_UP_URL = "https://isserverupapi.novisync.com"

// 2.0.6 : NormalVersion 04-22-2021
// 2.0.7 : Up & Down Information For Url Checking 04-28-2021
// 2.0.8 : Port Update Issue Resolved 04-29-2021
// 2.0.9 : Tables Issue Resolved 05-04-2021
// 3.0.0 : Check Path Validation Update 05-05-2021
// 3.0.1 : added individual pod information 12-05-2021
// 3.0.2 : getting server information dynamically 18-05-2021
// 3.1.0: Bug Fixes, Removed Port option 18-05-2021
//3.1.1: Bug Fixes, Database Properties issue resolved 06-02-2021
//3.2.0: Php-mysql migrated 03-06-2021
//3.2.1: Java migrated with rar 20-07-2021
//3.2.2: Java migrated with var 26-07-2021
//4.0.0: Application Dependeincies removed
//4.0.1: Added Password Check
// 4.1.0 Laravel added
//4.2.0 Python+MySql Added
//4.2.1 Laravel+MYsql Added
//4.3.0 MongoDb added
//4.4.0 gcp added for php with bugs 10-19-2021
//4.5.0 postgres+postgres php added 10-26-2021
//4.6.0 Postgres+pernstack added 11-20-2021
//4.7.0 AWS+PHP added 12-10-2021
//4.7.1 Bug Fixes
