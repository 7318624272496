import axios from 'axios'
import { API_URL } from '../../../config';
import { getToken } from '../LocalStorage';

export const axiosInstance = axios.create({
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    },
    baseURL: API_URL,
    responseType: "json",
    timeOut: 1000000
});

const requestHandler = (request) => {
    if (request.headers.isTokenRequired) {
        request.headers.common['x-access-token'] = getToken('auth')
    }
    delete request.headers.isTokenRequired
    return request
}

const errorHandler = (err) => {
    if (err.response) {
        switch (err.response.status) {
            case 400:
            case 401:
            case 406:
            case 409:
                throw Error(err.response.data.message);
            case 404:
                throw Error("Page Not Found");
            case 500:
                throw Error("Service Not Available");
            default:
                throw Error("Sorry Something Went Wrong, Please Try Again...");
        }
    } else {
        throw Error("Sorry Something Went Wrong, Please Try Again...");
    }

}
axiosInstance.interceptors.request.use((request) => requestHandler(request))
axiosInstance.interceptors.response.use(
    response => response
    , error => errorHandler(error)
)

