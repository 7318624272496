import React from 'react'

export default function Footer() {
    return (
        <footer className="footer" style={{position: 'fixed'}}>
            <div className="row">
                <div className="col-12 col-sm-6 text-center text-sm-left">
                    <p>&copy; Copyright {new Date().getFullYear()}. All rights reserved.</p>
                </div>
                <div className="col  col-sm-6 ml-sm-auto text-center text-sm-right">
                    <p><a  href="https://www.novisync.com"><img src="./assets/img/novisync.png" width="100px" alt="footer"/></a></p>
                </div>
            </div>
        </footer>
    )
}