import React, { useState, useReducer, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { reducer, initialState } from './reducer';
import * as actions from './actions';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../Common/Network/API';
import Alert from '../../Common/Alert';
import { getMigrationData, checkCredential, checkPath } from './network';
import { isLoading, isLoaded, isRedirect } from '../../Common/Actions/loading';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
import io from 'socket.io-client';
import { API_URL } from '../../../config';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    paper: {
        width: '100%',
        padding: theme.spacing(2, 4, 3),
    },
}));

// user document model and immigration admin model also
export default function UpdateModal(props) {

    console.log(props)


    const dispatcher = useDispatch();

    /*
   * Fetching ReqestId from localstorage
   */
    const classNames = useStyles();

    const [state, dispatch] = useReducer(reducer, initialState);
    const loaderStatus = useSelector(state => state.loading.status)
    const [portUpdate, setPortUpdate] = useState(false)
    const [socketId, setSocketId] = useState()
    const [buttonStatus, setButtonStatus] = useState(false)
    const [projectNameError, setProjectNameError] = useState(null)


    var migrate = props.migrate

    useEffect(() => {
        const socket = io(API_URL);
        socket.on('connection-success', success => {
            setSocketId(success.success)
            socket.emit('socked-id', success.success)
        })
        socket.on('connection-message', message => {
            message.success ? Alert('success', message.success) : Alert('error', message.error)
        })

        // socket.on('dbMessage', message => {
        //     setClick(true)
        //     handleDbStatus(message)
        // })

        return () => socket.disconnect();

    }, [])

    useEffect(() => {
        if (state.port.value.length === 5) {
            dispatch(actions.port(state.port.value, info, dispatch, dispatcher))
        }
    }, [portUpdate])


    useEffect(() => {
        props.type === "application" ?
            dispatch(actions.setApplicationMigrate(
                migrate.custom_path,
                migrate.db_name,
                migrate.db_password,
                migrate.destination_ip,
                migrate.id,
                migrate.migrate_type_id,
                migrate.migration_status,
                migrate.migration_type,
                migrate.name,
                migrate.password,
                migrate.port,
                migrate.status,
                migrate.sub_type,
                migrate.test_con_status,
                migrate.time_stamp,
                migrate.username,
                migrate.vm_source_ip,
                migrate.work_flow_id,
                migrate.db_user,
                migrate.destination_user,
                migrate.destination_password,
                migrate.resource_cpu,
                migrate.resource_memory,
                migrate.min_replicas,
                migrate.max_replicas,
                migrate.target_trigger,
                migrate.java_build_type,
                migrate.project_name,
                migrate.source_host_type,
                migrate.destination_host_type,
                migrate.destination_ssh_key,
                migrate.destination_project_id,
                migrate.destination_account_id,
                migrate.destination_project_region
            )
            )
            :
            dispatch(actions.setDatabaseMigrate(
                migrate.custom_path,
                migrate.db_name,
                migrate.db_password,
                migrate.destination_ip,
                migrate.id,
                migrate.migrate_type_id,
                migrate.migration_status,
                migrate.migration_type,
                migrate.name,
                migrate.password,
                migrate.port,
                migrate.status,
                migrate.sub_type,
                migrate.test_con_status,
                migrate.time_stamp,
                migrate.username,
                migrate.vm_source_ip,
                migrate.work_flow_id,
                migrate.db_user,
                migrate.destination_user,
                migrate.destination_password,
                migrate.resource_cpu,
                migrate.resource_memory,
                migrate.min_replicas,
                migrate.max_replicas,
                migrate.target_trigger,
                migrate.java_build_type,
                migrate.project_name,
                migrate.source_host_type,
                migrate.destination_host_type,
                migrate.destination_ssh_key,
                migrate.destination_project_id,
                migrate.destination_account_id,
                migrate.destination_project_region
            )
            )

    }, [])


    const updateTestConnection = async (e, value) => {
        e.preventDefault()
        const migrateData = {
            custom_path: state.custom_path.value,
            db_name: state.db_name.value,
            db_user: state.db_user.value,
            db_password: state.db_password.value,
            destination_ip: state.destination_ip.value,
            migrate_type_id: state.migrate_type_id.value,
            password: state.password.value,
            port: state.port.value,
            sub_type: state.sub_type.value,
            username: state.username.value,
            vm_source_ip: state.vm_source_ip.value,
            work_flow_id: props.work_flow_id,
            destination_user: state.destination_user.value,
            destination_password: state.destination_password.value,
            resource_cpu: state.resource_cpu.value,
            resource_memory: state.resource_memory.value,
            min_replicas: state.min_replicas.value,
            max_replicas: state.max_replicas.value,
            target_trigger: state.target_trigger.value,
            java_build_type: state.java_build_type.value,
            project_name: state.project_name.value,
            source_host_type: state.source_host_type.value,
            destination_host_type: state.destination_host_type.value,
            destination_ssh_key: state.destination_ssh_key.value,
            destination_project_id: state.destination_project_id.value,
            destination_account_id: state.destination_account_id.value,
            destination_project_region: state.destination_project_region.value

        }
        dispatcher(isLoading());
        try {
            const response = await API.post('connectApi/testConnection', {
                data: value === 'database' ? migrateData : value === 'application' ? migrateData : null,

            }, {}, true);
            getMigrationData(dispatch, props.work_flow_id)
            props.handleClose()
            // Alert('success', response.message)  
            dispatcher(isLoaded());
            window.location.reload();
        }
        catch (error) {
            props.handleClose()
            Alert('error', error.message)
        }
        dispatcher(isLoaded());
    }

    var info = {
        host: state.destination_ip.value,
        user: state.destination_user.value,
        password: state.destination_password.value,
    }

    var info1 = {
        host: state.vm_source_ip.value,
        user: state.username.value,
        password: state.password.value,
    }

    const handleCheckPath = (e) => {
        console.log(e.target.value, 'narendra')
        checkPath(dispatch, dispatcher, info1, e.target.value)
    }

    const handleUpdatePort = (event) => {
        if (event.target.value.length === 5) {
            setPortUpdate(!portUpdate)
        }
        dispatch(actions.updatePort(event.target.value))
    }

    const handleCheckAuthentication = () => {
        if (state.password.value && state.username.value && state.vm_source_ip.value) {
            var info = {
                password: state.password.value,
                username: state.username.value,
                ip: state.vm_source_ip.value
            }
            checkCredential(dispatch, dispatcher, info, 'source')
        }
    }

    const handleCheckAuthenticationDestination = () => {
        if (state.destination_ip.value && state.destination_user.value && state.destination_password.value) {
            var info = {
                password: state.destination_password.value,
                username: state.destination_user.value,
                ip: state.destination_ip.value
            }
            checkCredential(dispatch, dispatcher, info, 'destination')
        }
    }

    useEffect(() => {
        if (state.port_status.value.message === 'Please choose port between 30000 to 32767' || state.port_status.value.message === false) {
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
        }
    }, [state.port_status])

    useEffect(() => {
        if (state.check_cred.value === false || state.check_path.value === false || state.check_cred_destination.value === false) {
            setButtonStatus(true)
        } else {
            setButtonStatus(false)
        }
    }, [state.check_cred, state.check_path, state.check_cred_destination])

    console.log(state)

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classNames.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                disableBackdropClick={true}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classNames.paper}>
                        <div className="user-modal-dialog">
                            <div className="modal-content">
                                <button type="button" className="close" data-dismiss="modal"><i className="icon-xs-o-md"></i></button>
                                <div className="modal-header">
                                    <h4 className="modal-title caps"><strong>{props.type} - {props.migrate.name} - Information </strong></h4>
                                </div>
                                <form onSubmit={(e) => updateTestConnection(e, props.type)}>
                                    <div className="user-modal-body" style={{ height: '75vh', overflowY: 'auto', overflowX: 'hidden' }} >
                                        <div>
                                            {/* <strong className="mt-2 pt-3 ml-3">Application Information</strong> */}
                                            <hr></hr>
                                            {
                                                props.type === "application" ?
                                                    <div className="row pl-5">
                                                        <div className="form-group col-6">
                                                            <h5>Source Information</h5>
                                                            <label className="d-block" for="sourceHost">Source Host Type</label>
                                                            {/* <div className="row col-12"> */}
                                                            <select className="form-control" name="sourceHost" id="sourceHost" onChange={(event) => dispatch(actions.source_host_type(event.target.value))}>
                                                                <option value={state.source_host_type.value}>{state.source_host_type.value}</option>
                                                                <option value="AWS-VM">AWS-VM</option>
                                                                <option value="Azure-VM">Azure-VM</option>
                                                                <option value="GCP-VM">GCP-VM</option>
                                                                <option value="Nutanix-VM">Nutanix-VM</option>
                                                                <option value="Oracle-VM">Oracle-VM</option>
                                                                <option value="Redhat-VM">Redhat-VM</option>
                                                                <option value="VMWare-VM">VMware-VM</option>
                                                            </select>
                                                            {/* <select className="form-control col-5 ml-2">
                                                                    <option value="">Select Container</option>
                                                                    <option value="VMWare-Container">VMware-Container</option>
                                                                    <option value="Nutanix-Container">Nutanix-Container</option>
                                                                    <option value="AWS-Container">AWS-Container</option>
                                                                    <option value="GCP-Container">GCP-Container</option>
                                                                    <option value="AZure-Container">n-Container</option>
                                                                </select> */}
                                                            {/* </div> */}
                                                            <label className="d-block" for="sourceIp">Source Ip Address</label>
                                                            <input id="sourceIp" className='form-control' type="text" value={state.vm_source_ip.value} name="vmSourceIp" placeholder="Source Host Ip Address"
                                                                onChange={(event) => dispatch(actions.vm_source_ip(event.target.value))} required />
                                                            <label className="d-block" for="user">User</label>
                                                            <input id="user" className='form-control' type="text" value={state.username.value} name="username" placeholder="User"
                                                                onChange={(event) => dispatch(actions.username(event.target.value))} required />
                                                            <label className="d-block" for="password">Password</label>
                                                            <input id="password" className='form-control' type="password" value={state.password.value} name="password" placeholder="Password"
                                                                onBlur={() => handleCheckAuthentication()} onChange={(event) => dispatch(actions.password(event.target.value))} required />
                                                            {state.vm_source_ip.value && state.username.value && state.password.value && (
                                                                loaderStatus ? "Loading...." :
                                                                    state.check_cred.value === true ? <p style={{ color: 'green' }}>Authenticated</p> : state.check_cred.value === false ? <p style={{ color: 'red' }}>Invalid Credentials</p> : ""

                                                            )
                                                            }
                                                            <label className="d-block" for="pName">Project Name</label>
                                                            <input id="pName" className='form-control' type="text" value={state.project_name.value} name="projectName"
                                                                onChange={(event) => {
                                                                    setProjectNameError(null)
                                                                    const re = /^[A-Za-z]+$/;
                                                                    if (event.target.value === "" || re.test(event.target.value)) {
                                                                        dispatch(actions.project_name(event.target.value))
                                                                    } else {
                                                                        setProjectNameError("Enter Project Name Without Spaces, Numbers, Special Characters ")
                                                                    }

                                                                }}
                                                                required pattern="[^\s]+" title="Enter Project Name Without Spaces" />

                                                            {
                                                                props.migrate.sub_type === 3 ?

                                                                    <div>
                                                                        <label className="d-block" for="buildType">Build Type</label>
                                                                        <select className="form-control" id="buildType" name="java_build_type" value={state.java_build_type.value} onChange={(event) => dispatch(actions.javaBuild(event.target.value))} required >
                                                                            <option value="">Select Build Type</option>
                                                                            <option value="jar">jar</option>
                                                                            <option value="war">war</option>
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                props.migrate.sub_type === 10 || props.migrate.sub_type === 11 ?

                                                                    <div>
                                                                        <label className="d-block" for="buildType">Project Type</label>
                                                                        <select className="form-control" id="buildType" name="java_build_type" value={state.java_build_type.value} onChange={(event) => dispatch(actions.javaBuild(event.target.value))} required >
                                                                            <option value="">Select Project Type</option>
                                                                            <option value="flask">Flask</option>
                                                                            <option value="django">Django</option>
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    null
                                                            }

                                                            <label className="d-block" for="customPath">Browse Path</label>
                                                            <input id="customPath" className='form-control' type="text" value={state.custom_path.value} name="customPath" placeholder="Browse Path"
                                                                onBlur={e => handleCheckPath(e)} onChange={(event) => dispatch(actions.custom_path(event.target.value))} required />
                                                            {
                                                                loaderStatus ? "Loading...." :
                                                                    state.check_path.value === true ? <p style={{ color: 'green' }}>Valid Path</p> : state.check_path.value === false ? <p style={{ color: 'red' }}>Invalid Path</p> : ""
                                                            }
                                                        </div>
                                                        <div className="form-group col-6">
                                                            <h5>Destination Information</h5>
                                                            <label className="d-block" for="destinationHost">Destination Host</label>
                                                            <select className="form-control" name="destinationHost" id="destinationHost" onChange={(event) => dispatch(actions.destination_host_type(event.target.value))}>
                                                                <option value={state.destination_host_type.value}>{state.destination_host_type.value}</option>
                                                                <option value="AWS-Container">AWS-Container</option>
                                                                <option value="Azure-Container">Azure-Container</option>
                                                                <option value="GCP-Container">GCP-Container</option>
                                                                <option value="Nutanix-Container">Nutanix-Container</option>
                                                                <option value="Oracle-Container">Oracle-Container</option>
                                                                <option value="Redhat-Container">Redhat-Container</option>
                                                                <option value="VMWare-Container">VMware-Container</option>
                                                            </select>
                                                            <label className="d-block" for="destinationIp">Destination Ip Address</label>
                                                            <input id="destinationIp" className='form-control' type="text" value={state.destination_ip.value} name="destinationIp" placeholder="Destination Host Ip Address"
                                                                onChange={(event) => dispatch(actions.destination_ip(event.target.value))} required />
                                                            <label className="d-block" for="user">User</label>
                                                            <input id="user" value={state.destination_user.value} className='form-control' type="text" name="destinationUser" placeholder="User"
                                                                onChange={(event) => dispatch(actions.destination_user(event.target.value))} required />
                                                            {
                                                                state.destination_host_type.value === 'GCP-Container' ?
                                                                    <>
                                                                        {/* <label className="d-block" for="sshkey">SSH KEY</label>
                                                                        <textarea id="sshkey" className='form-control' name="sshkey" placeholder="SSH KEY"
                                                                            value={state.destination_ssh_key.value} onChange={(event) => dispatch(actions.destination_ssh_key(event.target.value))} required /> */}
                                                                        <label className="d-block" for="project_id">Project Id</label>
                                                                        <input id="project_id" className='form-control' type="text" name="project_id" placeholder="project Id"
                                                                            value={state.destination_project_id.value} onChange={(event) => dispatch(actions.destination_project_id(event.target.value))} required />

                                                                    </>
                                                                    :
                                                                    <>
                                                                        <label className="d-block" for="password">Password</label>
                                                                        <input id="password" value={state.destination_password.value} className='form-control' type="password" name="password" placeholder="Password"
                                                                            onChange={(event) => dispatch(actions.destination_password(event.target.value))}

                                                                            onBlur={() => { state.destination_host_type.value === 'AWS-Container' ? console.log("here") : handleCheckAuthenticationDestination() }}
                                                                            required />
                                                                        {
                                                                            state.destination_ip.value && state.destination_user.value && state.destination_password.value && (
                                                                                loaderStatus ? "Loading...." :
                                                                                    state.check_cred_destination.value === true ? <p style={{ color: 'green' }}>Authenticated</p> : state.check_cred_destination.value === false ? <p style={{ color: 'red' }}>Invalid Credentials</p> : ""
                                                                            )
                                                                        }

                                                                    </>
                                                            }
                                                            {
                                                                state.destination_host_type.value === 'AWS-Container' &&
                                                                <>
                                                                    <label className="d-block" for="account_id">Account Id</label>
                                                                    <input id="account_id" value={state.destination_account_id.value} className='form-control' type="text" name="account_id" placeholder="Account Id"
                                                                        onChange={(event) => dispatch(actions.destination_account_id(event.target.value))} required />
                                                                    <label className="d-block" for="region">Region</label>
                                                                    <input id="region" value={state.destination_project_region.value} className='form-control' type="text" name="region" placeholder="Region"
                                                                        onChange={(event) => dispatch(actions.destination_project_region(event.target.value))} required />
                                                                </>

                                                            }

                                                            {
                                                                (props.migrate.sub_type === 17 || props.migrate.sub_type === 1)  && (
                                                                    <>
                                                                        <label className="d-block" for="port">Destination Port</label>
                                                                        <input id="port" className='form-control' type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 5)} name="port" value={state.port.value} placeholder="Destination port"
                                                                            onChange={(event) => handleUpdatePort(event)} required />
                                                                        {
                                                                            loaderStatus ? "Loading...." :
                                                                                state.port_status.value.message === true ? <p style={{ color: 'green' }}>Port Available</p> : state.port_status.value.message === false ? <p style={{ color: 'red' }}>Port Not Available, Please Try another Port</p> : <p style={{ color: 'red' }}>{state.port_status.value.message}</p>}

                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div>
                                                        <div className='row pl-5'>
                                                            <div className="form-group col-6">
                                                                <h5>Source Information</h5>
                                                                <label className="d-block" for="sourceHost">Source Host</label>
                                                                <select className="form-control" name="sourceHost" id="sourceHost" onChange={(event) => dispatch(actions.source_host_type(event.target.value))}>
                                                                    <option value={state.source_host_type.value}>{state.source_host_type.value}</option>
                                                                    <option value="AWS-VM">AWS-VM</option>
                                                                    <option value="Azure-VM">Azure-VM</option>
                                                                    <option value="GCP-VM">GCP-VM</option>
                                                                    <option value="Nutanix-VM">Nutanix-VM</option>
                                                                    <option value="Oracle-VM">Oracle-VM</option>
                                                                    <option value="Redhat-VM">Redhat-VM</option>
                                                                    <option value="VMWare-VM">VMware-VM</option>
                                                                </select>
                                                                <label className="d-block" for="sourceIp">Source Ip Address</label>
                                                                <input id="sourceIp" className='form-control' type="text" value={state.vm_source_ip.value} name="vmSourceIp" placeholder="Source Host Ip Address"
                                                                    onChange={(event) => dispatch(actions.vm_source_ip(event.target.value))} required />
                                                                <label className="d-block" for="user">User</label>
                                                                <input id="user" className='form-control' type="text" value={state.username.value} name="username" placeholder="User"
                                                                    onChange={(event) => dispatch(actions.username(event.target.value))} required />
                                                                <label className="d-block" for="password">Password</label>
                                                                <input id="password" className='form-control' value={state.password.value} type="password" name="password" placeholder="Password"
                                                                    onBlur={() => handleCheckAuthentication()} onChange={(event) => dispatch(actions.password(event.target.value))} required />
                                                                {state.vm_source_ip.value && state.username.value && state.password.value && (
                                                                    loaderStatus ? "Loading...." :
                                                                        state.check_cred.value === true ? <p style={{ color: 'green' }}>Authenticated</p> : state.check_cred.value === false ? <p style={{ color: 'red' }}>Invalid Credentials</p> : ""

                                                                )
                                                                }
                                                                <label className="d-block" for="databaseName">Data Base Name</label>
                                                                <input id="databaseName" className='form-control' type="text" value={state.db_name.value} name="databaseName" placeholder="Database Name"
                                                                    onChange={(event) => dispatch(actions.db_name(event.target.value))} required />
                                                                <label className="d-block" for="databaseName">Data Base User</label>
                                                                <input id="databaseUser" className='form-control' type="text" value={state.db_user.value} name="databaseUser" placeholder="Database User"
                                                                    onChange={(event) => dispatch(actions.db_user(event.target.value))} required />
                                                                <label className="d-block" for="databasePassword">Data Base Password</label>
                                                                <input id="databasePassword" className='form-control' type="password" value={state.db_password.value} name="databasePassword" placeholder="Database Password"
                                                                    onChange={(event) => dispatch(actions.db_password(event.target.value))} required />

                                                            </div>
                                                            <div className="form-group col-6">
                                                                <h5>Destination Information</h5>

                                                                <label className="d-block" for="destinationHost">Destination Host</label>
                                                                <select className="form-control" name="destinationHost" id="destinationHost" onChange={(event) => dispatch(actions.destination_host_type(event.target.value))}>
                                                                    <option value={state.destination_host_type.value}>{state.destination_host_type.value}</option>
                                                                    <option value="AWS-Container">AWS-Container</option>
                                                                    <option value="Azure-Container">Azure-Container</option>
                                                                    <option value="GCP-Container">GCP-Container</option>
                                                                    <option value="Nutanix-Container">Nutanix-Container</option>
                                                                    <option value="Oracle-Container">Oracle-Container</option>
                                                                    <option value="Redhat-Container">Redhat-Container</option>
                                                                    <option value="VMWare-Container">VMware-Container</option>
                                                                </select>
                                                                <label className="d-block" for="destinationIp">Destination Ip Address</label>
                                                                <input id="destinationIp" className='form-control' type="text" value={state.destination_ip.value} name="destinationIp" placeholder="Destination Host Ip Address"
                                                                    onChange={(event) => dispatch(actions.destination_ip(event.target.value))} required />
                                                                <label className="d-block" for="destinationUser">Destination User</label>
                                                                <input id="destinationUser" className='form-control' type="text" value={state.destination_user.value} name="destinationUser" placeholder="Destination Host Ip Address"
                                                                    onChange={(event) => dispatch(actions.destination_user(event.target.value))} required />
                                                                {
                                                                    state.destination_host_type.value === 'AWS-Container' ?
                                                                        <>
                                                                            <label className="d-block" for="password">Destination Password</label>
                                                                            <input id="password" value={state.destination_password.value} className='form-control' type="password" name="password" placeholder="Password"
                                                                                onChange={(event) => dispatch(actions.destination_password(event.target.value))} required />
                                                                            <label className="d-block" for="account_id">Account Id</label>
                                                                            <input id="account_id" value={state.destination_account_id.value} className='form-control' type="text" name="account_id" placeholder="Account Id"
                                                                                onChange={(event) => dispatch(actions.destination_account_id(event.target.value))} required />
                                                                            <label className="d-block" for="region">Region</label>
                                                                            <input id="region" value={state.destination_project_region.value} className='form-control' type="text" name="region" placeholder="Region"
                                                                                onChange={(event) => dispatch(actions.destination_project_region(event.target.value))} required />
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <label className="d-block" for="destinationPassword">Destination Password</label>
                                                                            <input id="password" value={state.destination_password.value} className='form-control' type="password" name="password" placeholder="Password"
                                                                                onChange={(event) => dispatch(actions.destination_password(event.target.value))} onBlur={() => handleCheckAuthenticationDestination()} required />

                                                                        </>
                                                                }
                                                                {}

                                                                {
                                                                    state.destination_ip.value && state.destination_user.value && state.destination_password.value && (
                                                                        loaderStatus ? "Loading...." :
                                                                            state.check_cred_destination.value === true ? <p style={{ color: 'green' }}>Authenticated</p> : state.check_cred_destination.value === false ? <p style={{ color: 'red' }}>Invalid Credentials</p> : ""
                                                                    )
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                            }
                                        </div>
                                        <div>
                                            <strong className="mt-2 pt-3 ml-3">Container Information</strong>
                                            <hr></hr>
                                            <div className="form-group col-6 pl-5">
                                                {/* <label className="d-block" for="resourceName">Resource Name</label>
                                                <input id="resourceName" className='form-control' type="text" name="resourceName" placeholder="Resource Name"
                                                    onChange={(event) => dispatch(actions.resourceName(event.target.value))} required /> */}
                                                <label className="d-block" for="resourceName">Memory</label>
                                                Maximum Memory Allocation (MB)
                                                <div className="p-3">
                                                    <InputRange
                                                        maxValue={4143}
                                                        minValue={0}
                                                        value={state.resource_memory.value}
                                                        onChange={value => dispatch(actions.resourceMemory(value))} />
                                                </div>
                                                <label className="d-block" for="resourceName">CPU</label>
                                                Maximum CPU Allocation
                                                <div className="p-3">
                                                    <InputRange
                                                        maxValue={2.0}
                                                        minValue={0.0}
                                                        value={state.resource_cpu.value}
                                                        onChange={value => dispatch(actions.resourceCpu(value))} />
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <label className="d-block" for="minReplicas">Min Replicas</label>
                                                        <input id="minReplicas" value={state.min_replicas.value} className='form-control' type="number" name="minReplicas"
                                                            onChange={(event) => dispatch(actions.minReplicas(event.target.value))} required />
                                                    </div>
                                                    <div className="col-4">
                                                        <label className="d-block" for="maxReplicas">Max Replicas</label>
                                                        <input id="maxReplicas" value={state.max_replicas.value} className='form-control' type="number" name="minReplicas"
                                                            onChange={(event) => dispatch(actions.maxReplicas(event.target.value))} required />
                                                    </div>
                                                    <div className="col-4">
                                                        <label className="d-block" for="triggerTarget">Trigger Target %</label>
                                                        <input id="triggerTarget" value={state.target_trigger.value} className='form-control' type="number" name="triggerTarget"
                                                            onChange={(event) => dispatch(actions.triggerTarget(event.target.value))} required />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        {console.log(buttonStatus, 'narendra')}
                                        {
                                            props.type === 'application' ?
                                                <button disabled={buttonStatus || loaderStatus ? true : false} type="submit" className="btn btn-primary">{loaderStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : "Test Connection"}</button>
                                                :
                                                <button disabled={loaderStatus ? true : false} type="submit" className="btn btn-primary">{loaderStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : "Test Connection"}</button>
                                        }
                                        {/* <button disabled={loaderStatus ? true : false} type="submit" className="btn btn-primary">{loaderStatus ? <img src="assets/img/loader/smallLoader.gif" style={{ width: '20px' }} /> : "Test Connection"}</button> */}

                                        <button type="button" onClick={props.handleClose} className="btn btn-danger" data-dismiss="modal">Close</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}