import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import Dashboard from './Components/Dashboard/Dashboard';
import WorkFlow from './Components/Dashboard/WorkFlow';
import Migrate from './Components/Dashboard/Migrate';

function Routes() {



  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/register" component={Register} exact />
        <Route path="/dashboard" component={Dashboard} exact />
        <Route path="/workFlow" component={WorkFlow} exact />
        <Route path="/migrate" component={Migrate} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
