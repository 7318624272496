import { IS_LOADING, IS_LOADED, IS_REDIRECT } from './actions'
export function isLoading() {
    return { type: IS_LOADING };
}
export function isLoaded() {
    return { type: IS_LOADED }
}
export function isRedirect(value) {
    return {
        type: IS_REDIRECT,
        payLoad: value
    }
}
