import API from "../../Common/Network/API"
import { migrationType, subMigrationType, migrationData, check_credential_source, check_credential_destination, check_path } from "./actions"
import Alert from "../../Common/Alert"
import { isLoading, isLoaded } from '../../Common/Actions/loading';

export async function getMigrationType(dispatch) {
  try {
    var response = await API.get('/workFlow/getMigrationType', true)
    dispatch(migrationType(response))
  } catch (error) {
    Alert("error", error.message)
  }
}

export async function getSubMigrationType(dispatch, migrationType, work_flow_id) {
  try {
    var response = await API.post('/workFlow/getSubMigrationType', { migrationTypeId: migrationType, work_flow_id: work_flow_id }, {}, true)
    dispatch(subMigrationType(response))
  } catch (error) {
    Alert("error", error.message)
  }
}

export async function getMigrationData(dispatch, migrationType) {
  try {
    var response = await API.post('/workFlow/getMigrationData', { migrationTypeId: migrationType }, {}, true)
    dispatch(migrationData(response))
  } catch (error) {
    Alert("error", error.message)
  }
}

export async function handleMigrate(dispatch, data) {
  dispatch(isLoading());
  try {
    var response = await API.post('/connectApi/migrate',
      { data: data },
      {},
      true)
    dispatch(isLoaded());
  } catch (error) {
    Alert("error", error.message)
  }
  dispatch(isLoaded());
}

export async function checkCredential(dispatch, dispatcher, data, type) {
  dispatcher(isLoading());
  try {
    var response = await API.post('/connectApi/checkCredential',
      { data: data },
      {},
      true)
    dispatcher(isLoaded());
    if (type === 'source') {
      dispatch(check_credential_source(response.message))
    } else {
      dispatch(check_credential_destination(response.message))
    }
  } catch (error) {
    Alert("error", error.message)
  }
  dispatcher(isLoaded());
}

export async function checkPath(dispatch, dispatcher, data, path) {
  dispatcher(isLoading());
  try {
    var response = await API.post('/connectApi/checkPath',
      { data: data, path: path },
      {},
      true)
    dispatcher(isLoaded());
    dispatch(check_path(response.message))
  } catch (error) {
    Alert("error", error.message)
  }
  dispatcher(isLoaded());
}