import React, { useState, useReducer, useEffect } from 'react'
import TopNavbar from '../Utility/TopNavbar'
import SideNavbar from '../Utility/SideNavbar'
import Footer from '../Utility/Footer'
import { useSelector } from 'react-redux'
import { initialState, reducer } from './reducer'
import { addWorkFlow } from './network'
import { getPendingWorkFlow, getCompletedWorkFlow, Message } from './actions'
import moment from 'moment'
import { Link } from 'react-router-dom'
import $ from 'jquery'



export default function WorkFlow() {

    const [data, setData] = useState({})
    const [state, dispatch] = useReducer(reducer, initialState)
    const [completedWorkFlowData, setCompletedWorkFlowData] = useState([])
    const [pendingWorkFlowData, setPendingWorkFlowData] = useState([])

    const loaderStatus = useSelector(state => state.loading.status)


    useEffect(() => {
        fun();
        // eslint-disable-next-line
    }, {})


    const fun = () => {
        if (window.localStorage) {
            if (!localStorage.getItem('firstLoad')) {
                localStorage['firstLoad'] = true;
                window.location.reload();
            }
            else
                localStorage.removeItem('firstLoad');
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const addFlow = () => {
        addWorkFlow(dispatch, data)
    }

    useEffect(() => {
        getPendingWorkFlow(dispatch)
        getCompletedWorkFlow(dispatch)

    }, [])


    if (state.message) {
        setTimeout(() => {
            dispatch(Message(""))
        }, 2000);
    }


    useEffect(() => {
        setPendingWorkFlowData(state.pendingWorkFlow)
    }, [state.pendingWorkFlow])

    useEffect(() => {
        if (pendingWorkFlowData.length > 0) {
            $(document).ready(function () {
                window.$('#example1').DataTable()
            })
        }
    }, pendingWorkFlowData)

    useEffect(() => {
        setCompletedWorkFlowData(state.completedWorkFlow)
    }, [state.completedWorkFlow])

    useEffect(() => {
        if (completedWorkFlowData.length > 0) {
            $(document).ready(function () {
                window.$('#example2').DataTable()
            })
        }
    }, [completedWorkFlowData])


    const tableData1 = pendingWorkFlowData.map((x, key) => {
        return (
            <tr key={key}>
                <td>{key + 1}</td>
                <td style={{ color: 'blue', textDecoration: 'underLine' }}><Link to={{
                    pathname: '/migrate', state: {
                        id: x.id,
                        name: x.name
                    }
                }}>{x.name}</Link></td>
                {/* <td><span className="badge badge-success-inverse">Active</span></td> */}
                <td>{moment(x.time_stamp).format("DD-MM-YYYY hh:mm:ss")}</td>
            </tr>
        )
    });

    const tableData2 = completedWorkFlowData.map((x, key) => {
        return (
            <tr key={key}>
                <td>{key + 1}</td>
                <td style={{ color: 'blue', textDecoration: 'underLine' }}><Link to={{
                    pathname: '/migrate', state: {
                        id: x.id,
                        name: x.name
                    }
                }}>{x.name}</Link></td>
                {/* <td><span className="badge badge-success-inverse">Active</span></td> */}
                <td>{moment(x.time_stamp).format("DD-MM-YYYY hh:mm:ss")}</td>
            </tr>
        )
    })



    return (
        <div>
            <TopNavbar />
            <div className="app-container">
                <SideNavbar />
                <div className="app-main" id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 m-b-30">

                                <div className="d-block d-sm-flex flex-nowrap align-items-center">
                                    <div className="page-title mb-2 mb-sm-0">
                                        {/* <h4>Work Flow</h4> */}
                                    </div>
                                    <div className="ml-auto d-flex align-items-center">
                                        <nav>
                                            <ol className="breadcrumb p-0 m-b-0">
                                                <li className="breadcrumb-item">
                                                    <a href="index.html"><i className="ti ti-home"></i></a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    Work Flow
                                                </li>
                                                {/* <li className="breadcrumb-item active text-primary" aria-current="page">WorkFlow</li> */}
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <div className="container-left ml-3">
                                    <div className="card card-statistics overflow-h">
                                        <div className="card-body p-0">
                                            <div className="d-flex m-b-0 contant-text h-100">
                                                <div className="w-100">
                                                    <div className="row p-3">
                                                        <div className="col">
                                                            <small className="d-block">New</small>
                                                            <h5 className="mb-0">Work Flow</h5>
                                                            <div>
                                                                <div class="card-body">
                                                                    <small className="d-block"></small>
                                                                    <div className="form-group mb-0">
                                                                        <input type="text" name="workFlow" className="form-control" placeholder="Work Flow Name" onChange={(e) => handleChange(e)} />
                                                                    </div>
                                                                    <div className='mt-2'>
                                                                        <button onClick={() => addFlow()} className="btn btn-primary btn-block">Add</button>
                                                                    </div>
                                                                    {state.message ? state.message : ""}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-8">
                                {state.pendingWorkFlow.length != 0 ?
                                    <div className="row">
                                        <div className="col-xxl-12 m-b-30">
                                            <div className="card card-statistics h-100 mb-0">
                                                <div className="card-header d-flex align-items-center justify-content-between">
                                                    <div className="card-heading">
                                                        <h5 className="card-title">Pending Work Flows</h5>
                                                    </div>
                                                    <div className="dropdown">
                                                        {/* <a className="btn btn-xs" href="#!">Export <i className="zmdi zmdi-download pl-1"></i> </a> */}
                                                    </div>
                                                </div>
                                                <div className="card-body scrollbar scroll_dark pt-0" style={{ maxHeight: '350px' }}>

                                                    <div className="datatable-wrapper table-responsive">
                                                        <table id="example1" className="table  table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Name</th>
                                                                    {/* <th>Status</th> */}
                                                                    <th>Time</th>
                                                                </tr>
                                                            </thead>
                                                            {
                                                                pendingWorkFlowData.length === 0 ?
                                                                    "No Data Found"
                                                                    :
                                                                    <tbody>
                                                                        {tableData1}
                                                                    </tbody>
                                                            }

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div> : null}                            </div>

                        </div>
                        {state.completedWorkFlow.length != 0 ?
                            <div className="row">
                                <div className="col-xxl-12 m-b-30">
                                    <div className="card card-statistics h-100 mb-0">
                                        <div className="card-header d-flex align-items-center justify-content-between">
                                            <div className="card-heading">
                                                <h5 className="card-title">Completed Work Flows</h5>
                                            </div>
                                            <div className="dropdown">
                                                {/* <a className="btn btn-xs" href="#!">Export <i className="zmdi zmdi-download pl-1"></i> </a> */}
                                            </div>
                                        </div>
                                        <div className="card-body scrollbar scroll_dark pt-0" >

                                            <div className="datatable-wrapper table-responsive">
                                                <table id="example2" className="table  table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            {/* <th>Status</th> */}
                                                            <th>Time</th>
                                                        </tr>
                                                    </thead>
                                                    {
                                                        completedWorkFlowData.length === 0 ?
                                                            "No Data Found"
                                                            :
                                                            <tbody>
                                                                {
                                                                    tableData2
                                                                }

                                                            </tbody>
                                                    }
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div> : null}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}