import React, { useReducer, useEffect, useState } from 'react'
import TopNavbar from '../Utility/TopNavbar'
import SideNavbar from '../Utility/SideNavbar'
import Footer from '../Utility/Footer'
import { useSelector } from 'react-redux'
import { initialState, reducer } from './reducer'
import { Bar } from 'react-chartjs-2';
import API from '../../Common/Network/API'
import { getWorkFlow } from './actions'
import { Link } from 'react-router-dom'
import { API_URL, SERVER_UP_URL } from '../../../config'
import io from 'socket.io-client';
import Axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
export function Data(props) {

    const [message, setMessage] = useState()

    props.data.then(res => {
        setMessage(res)
    })


    return (
        <div>
            {
                message !== undefined ?
                    message === 200 ?
                        <div style={{ color: 'green', fontWeight: 'bold' }} >
                            Up
                        </div>
                        :
                        <div style={{ color: 'red', fontWeight: 'bold' }} >
                            Down
                        </div>

                    :
                    <div style={{ color: 'black', fontWeight: 'bold' }} >
                        Loading...
                    </div>

            }

        </div>
    )
}



export default function Dashboard() {

    const [state, dispatch] = useReducer(reducer, initialState)
    const [workFlows, setWorkFlows] = useState([])
    const [migrations, setMigrations] = useState([])
    const [workFlowChart, setWorkFlowChart] = useState([])
    const [migrateChart, setMigrateChart] = useState([])
    const [pendingWorkFlows, setPendingWorkFlows] = useState([])
    const [completedWorkFlows, setCompletedWorkFlows] = useState([])
    const [migrationData, setMigrationData] = useState([])
    // const [socketId, setSocketId] = useState()
    // const socket = io(API_URL);
    const [podInfo, setPodInfo] = useState()
    const [podData, setPodData] = useState([])


    const loaderStatus = useSelector(state => state.loading.status)

    useEffect(() => {
        getWorkFlow(dispatch)
    }, [])

    useEffect(() => {
        fun();
        // eslint-disable-next-line
    }, {})


    const fun = () => {
        if (window.localStorage) {
            if (!localStorage.getItem('firstLoad')) {
                localStorage['firstLoad'] = true;
                window.location.reload();
            }
            else
                localStorage.removeItem('firstLoad');
        }
    }


    // console.log(socketId)

    //for socket connection
    // useEffect(() => {
    //     socket.on('connection-success', success => {
    //         setSocketId(success.success)
    //         socket.emit('socked-id', success.success)
    //     })
    // }, [])
    // useEffect(() => {

    //     socket.on('getAllPodS', message => {

    //         if (message.socket === socketId) {
    //             socket.on('allPod-success', message => {
    //                 setPodInfo(message.message)
    //             })

    //             socket.on('allPod-failure', message => {
    //                 setPodInfo(message.message)
    //             })
    //         }
    //     })

    //     // return () => socket.disconnect();

    // }, [socketId])

    useEffect(() => {
        var stingArray = podInfo && podInfo.replace(/  |,/gi, ",").split(',')

        setPodData(stingArray && stingArray.filter(Boolean))

    }, [podInfo])

    // useEffect(() => {
    //     // handleGetPodInfo()
    // }, [socketId])

    // const handleGetPodInfo = async (x) => {

    //     try {
    //         await API.post('/connectApi/getAllPods', { socket: socketId }, {}, true);

    //     }
    //     catch (error) {
    //         console.log(error.message)
    //     }
    // }

    useEffect(() => {

        async function Network(data, state) {
            try {
                const response = await API.get(`/dataApi/${data}`, {}, {}, true)
                state(response)
            }
            catch (error) {
                console.log('error', error.message)
            }
        }
        Network('workFlowsCount', setWorkFlows)
        Network('pendingWorkFlowsCount', setPendingWorkFlows)
        Network('completedWorkFlowsCount', setCompletedWorkFlows)
        Network('workFlowsCount', setWorkFlows)
        Network('migrationCount', setMigrations)
        Network('workFlowChart', setWorkFlowChart)
        Network('migrateChart', setMigrateChart)
    }, [])


    const workData = {
        labels: workFlowChart.map(x => x.name),
        datasets: [
            {
                label: 'WorkFlow Analysis',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(251,7,146,0.4)',
                borderColor: 'rgba(251,7,146,0.4)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'black',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 3,
                pointHitRadius: 10,
                data: workFlowChart.map(x => x.count)
            }
        ]
    };

    const migrateData = {
        labels: migrateChart.map(x => x.name),
        datasets: [
            {
                label: 'Migration Analysis',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(142,84,233,0.4)',
                borderColor: 'rgba(142,84,233,0.4)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'black',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 4,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 3,
                pointHitRadius: 10,
                data: migrateChart.map(x => x.count)
            }
        ]
    };


    const getPing = async (url) => {
        try {
            const response = await Axios.post(`${SERVER_UP_URL}/pingApi/pingStatus`, { url: url })
                .then(res => {
                    return res.data
                })
                .catch(err => {
                    return err.data
                })

            return response.message
        }
        catch (error) {
            console.log(error.message)
        }
    }


    useEffect(() => {
        setMigrationData(state.workFlow)
    }, [state.workFlow])

    useEffect(() => {
        if (migrationData.length > 0) {
            $(document).ready(function () {
                window.$('#example').DataTable({
                    "pageLength": migrationData.length,
                    "order": [[0, "asc"]]
                })
            })
        }
    }, [migrationData])


    const tableData =
        migrationData.map(x => {
            return (
                <tr key={x} style={{ textAlign: 'center' }}>
                    {/* <td>{moment(x.time_stamp).format('MM-DD-YY')}</td> */}
                    <td style={{ marginLeft: '5%', color: 'blue', textAlign: 'left', textDecoration: 'underLine' }}><Link to={{
                        pathname: '/migrate', state: {
                            id: x.work_flow_id,
                            name: x.work_flow_name
                        }
                    }}>{x.work_flow_name}</Link></td>
                    <td>{x.migration_type_name}</td>
                    <td>{x.sub_migration_type_name}</td>
                    <td>
                        {
                            x.test_con_status === 1 ?
                                <strong className="text-success m-t-5"> <i title="connection-success" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                :
                                <strong className="text-danger m-t-5"> <i title="connection-failed" className="zmdi zmdi-shield-check font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                        }
                    </td>
                    <td>
                        {
                            x.migration_status === 1 ?
                                <strong className="text-success m-t-5"> <i title="migration-success" className="zmdi zmdi-cloud-done font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                                :
                                <strong className="text-danger m-t-5"> <i title="migration-pending" className="zmdi zmdi-cloud-done font-weight-bold" style={{ fontSize: '20px' }}></i></strong>
                        }
                    </td>
                    <td>
                        <div className="row col-8 ml-5">
                            {
                                x.sub_migration_type_name !== 'MySql' && (
                                    <div>
                                        {
                                            x.public_url !== null ?
                                                <>
                                                    <div className="col-3 mr-3">
                                                        <Data data={getPing(x.public_url)} url={x.public_url} />
                                                    </div>
                                                    <div className="col-5 ml-3">
                                                        <a className="p-1 col-4" href={x.public_url} target="_blank" rel="noopener noreferrer" disabled><i title={x.public_url} className="zmdi zmdi-open-in-new" style={{ fontSize: '20px' }}></i></a>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div className="col-3 mr-3">
                                                        <Data data={getPing(`${x.destination_ip}:${x.port}`)} url={`${x.destination_ip}:${x.port}`} />
                                                    </div>
                                                    <div className="col-5 ml-3">
                                                        <a className="p-1 col-4" href={`http://${x.destination_ip}:${x.port}`} target="_blank" disabled><i title={`http://${x.destination_ip}:${x.port}`} className="zmdi zmdi-open-in-new" style={{ fontSize: '20px' }}></i></a>
                                                    </div>
                                                </>
                                        }

                                    </div>
                                )
                            }
                            {

                            }
                        </div>
                    </td>
                </tr>
            )
        })

    return (
        <div>
            <TopNavbar />
            <div className="app-container">
                <SideNavbar />
                <div className="app-main" id="main">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 m-b-30">
                                <div className="d-block d-sm-flex flex-nowrap align-items-center">
                                    <div className="ml-auto d-flex align-items-center">
                                        <nav>
                                            <ol className="breadcrumb p-0 m-b-0">
                                                <li className="breadcrumb-item">
                                                    <a href="index.html"><i className="ti ti-home"></i></a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    Dashboard
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6 col-xxl-4 m-b-30">
                                <div className="card card-statistics h-100 m-b-0 bg-pink">
                                    <div className="card-body">
                                        <h2 className="text-white mb-0">{workFlows.length}</h2>
                                        <p className="text-white">All Migration Requests</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-xxl-4 m-b-30">
                                <div className="card card-statistics h-100 m-b-0 bg-success">
                                    <div className="card-body">
                                        <h2 className="text-white mb-0">{completedWorkFlows.length}</h2>
                                        <p className="text-white">Completed Migrations</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-xxl-4 m-b-30">
                                <div className="card card-statistics h-100 m-b-0 bg-primary">
                                    <div className="card-body">
                                        <h2 className="text-white mb-0">{pendingWorkFlows.length}</h2>
                                        <p className="text-white">Pending Migrations</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                        {/* <div className="row">
                            <div className="col-xxl-6 m-b-30">
                                <div className="card card-statistics h-100 m-b-0">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="card-heading">
                                            <i className="card-title">Completed WorkFlow analysis</i>
                                        </div>
                                    </div>
                                    <Bar data={workData}
                                        options={{
                                            scales: {
                                                yAxes: [{
                                                    ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 1
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: 'Count'
                                                    }
                                                }],
                                                xAxes: [
                                                    {
                                                        scaleLabel: {
                                                            display: true,
                                                            labelString: 'Work Flows'
                                                        }
                                                    }
                                                ]
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-xxl-6 m-b-30">
                                <div className="card card-statistics h-100 m-b-0">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="card-heading">
                                            <i className="card-title">Migration analysis</i>
                                        </div>
                                    </div>
                                    <Bar data={migrateData}
                                        options={{
                                            scales: {
                                                yAxes: [{
                                                    ticks: {
                                                        beginAtZero: true,
                                                        stepSize: 1
                                                    },
                                                    scaleLabel: {
                                                        display: true,
                                                        labelString: 'Count'
                                                    }
                                                }],
                                                xAxes: [
                                                    {
                                                        scaleLabel: {
                                                            display: true,
                                                            labelString: 'Migration Blocks'
                                                        }
                                                    }
                                                ]
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                     */}
                        <div className="row">

                            <div className="col-xxl-12 m-b-30">
                                <div className="card card-statistics h-100 m-b-0">
                                    <div className="card-header d-flex justify-content-between">
                                        <div className="card-heading">
                                            <h4 className="card-title">Completed Migrations</h4>
                                        </div>

                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">

                                            <table id="example" className="table table-striped mb-0">
                                                <thead className="bg-light">
                                                    <tr style={{ textAlign: 'center' }}>
                                                        {/* <th>Created On</th> */}
                                                        <th>WorkFlow</th>
                                                        <th>Migration</th>
                                                        <th>Technology</th>
                                                        <th>Connection Status</th>
                                                        <th>Migration Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                {
                                                    migrationData.length === 0 ?
                                                        <center className="mt-2 mb-2">Loading...</center>
                                                        :
                                                        <tbody className="text-muted mb-0">
                                                            {
                                                                tableData
                                                            }
                                                        </tbody>
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
