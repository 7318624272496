import React from 'react'
import RootLoader from '../../Common/Loader/RootLoader'
import { Link } from 'react-router-dom'
import { VERSION_DATE, VERSION_NUM } from '../../../config'

export default function Register() {
    return (
        <div className="app">
            <div className="app-wrap">
                {/* <RootLoader /> */}

                <div className="app-contant">
                    <div className="bg-white">
                        <div className="container-fluid p-0">
                            <div className="row no-gutters">
                                <div className="col-sm-6 col-lg-5 col-xxl-3  align-self-center order-2 order-sm-1">
                                    <div className="d-flex align-items-center h-100-vh">
                                        <div className="register p-5">
                                            <h1 className="mb-2">PEREGRINIX</h1>
                                            <span style={{ color: '#9E9FA6', textAlign: 'left', fontSize: '10px', paddingLeft: '0%' }}>Version {VERSION_NUM}  {VERSION_DATE}</span>
                                            <form className="mt-2">
                                                <p>Welcome, Please create your account.</p>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="control-label">First Name*</label>
                                                            <input type="text" className="form-control" placeholder="First Name" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="form-group">
                                                            <label className="control-label">Last Name*</label>
                                                            <input type="text" className="form-control" placeholder="Last Name" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="control-label">Email*</label>
                                                            <input type="email" className="form-control" placeholder="Email" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="control-label">Username*</label>
                                                            <input type="text" className="form-control" placeholder="Username" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="control-label">Password*</label>
                                                            <input type="password" className="form-control" placeholder="Password" required />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="gridCheck" required />
                                                            <label className="form-check-label" for="gridCheck">
                                                                I accept terms & policy
                                                        </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <button type="submit" className="btn btn-primary text-uppercase">Sign up</button>
                                                    </div>
                                                    <div className="col-12  mt-3">
                                                        <p>Already have an account ?<Link to="/"> Sign In</Link></p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xxl-9 col-lg-7 bg-gradient o-hidden order-1 order-sm-2">
                                    <div className="row align-items-center h-100">
                                        <div className="col-7 mx-auto ">
                                            <img className="img-fluid" src="assets/img/bg/login.svg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}