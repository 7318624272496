import API from "../../Common/Network/API";

export const GET_PENDING_WORK_FLOWS = 'GET_PENDING_WORK_FLOWS'
export const GET_COMPLETED_WORK_FLOWS = 'GET_COMPLETED_WORK_FLOWS'
export const MESSAGE = 'MESSAGE'


export async function getCompletedWorkFlow(dispatch) {
    try {
        return await API.get(
            "/workFlow/getCompletedWorkFlow",
            true
        ).then(response => {
            dispatch({
                type: GET_COMPLETED_WORK_FLOWS,
                payload: response
            });
        });
    } catch (err) {
        dispatch({
            type: GET_COMPLETED_WORK_FLOWS,
            payload: err.message
        });
    }
};

export async function getPendingWorkFlow(dispatch) {
    try {
        return await API.get(
            "/workFlow/getPendingWorkFlow",
            true
        ).then(response => {
            dispatch({
                type: GET_PENDING_WORK_FLOWS,
                payload: response
            });
        });
    } catch (err) {
        dispatch({
            type: GET_PENDING_WORK_FLOWS,
            payload: err.message
        });
    }
};

export function Message(data) {
    return {
        type: MESSAGE,
        payload: data
    }
}