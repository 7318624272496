import { isLoaded, isLoading } from "../../Common/Actions/loading"
import API from "../../Common/Network/API"

export const MIGRATION_TYPE = "MIGRATION_TYPE"
export const SUB_MIGRATION_TYPE = "SUB_MIGRATION_TYPE"
export const MIGRATION_DATA = "MIGRATION_DATA"
export const SET_APPLICATION_MIGRATE = "SET_APPLICATION_MIGRATE"
export const SET_DATABASE_MIGRATE = "SET_DATABASE_MIGRATE"
export const VM_SOURCE_IP = "VM_SOURCE_IP"
export const CUSTOM_PATH = "CUSTOM_PATH"
export const DB_NAME = "DB_NAME"
export const DB_USER = "DB_USER"
export const DB_PASSWORD = "DB_PASSWORD"
export const NAME = "NAME"
export const PASSWORD = "PASSWORD"
export const MIGRATION_TYPE_ID = "MIGRATION_TYPE_ID"
export const DESTINATION_IP = "DESTINATION_IP"
export const PORT = "PORT"
export const SUB_TYPE = "SUB_TYPE"
export const USER_NAME = "USER_NAME"
export const DESTINATION_USER = 'DESTINATION_USER'
export const DESTINATION_PASSWORD = 'DESTINATION_PASSWORD'
export const RESOURCE_CPU = 'RESOURCE_CPU'
export const RESOURCE_MEMORY = 'RESOURCE_MEMORY'
export const MIN_REPLICAS = 'MIN_REPLICAS'
export const MAX_REPLICAS = 'MAX_REPLICAS'
export const TARGET_TRIGGER = 'TARGET_TRIGGER'
export const PORT_STATUS = 'PORT_STATUS'
export const JAVA_BUILD = 'JAVA_BUILD'
export const PROJECT_NAME = 'PROJECT_NAME'
export const SOURCE_HOST_TYPE = 'SOURCE_HOST_TYPE'
export const DESTINATION_HOST_TYPE = 'DESTINATION_HOST_TYPE'
export const CHECK_CRED = 'CHECK_CRED'
export const UPDATE_PORT = 'UPDATE_PORT'
export const CHECK_PATH = 'CHECK_PATH'
export const CHECK_CRED_DESTINATION = "CHECK_CRED_DESTINATION"
export const DESTINATION_SSH_KEY = "DESTINATION_SSH_KEY"
export const DESTINATION_PROJECT_ID = "DESTINATION_PROJECT_ID"
export const DESTINATION_ACCOUNT_ID = "DESTINATION_ACCOUNT_ID"
export const DESTINATION_PROJECT_REGION = "DESTINATION_PROJECT_REGION"

export function setApplicationMigrate(
    custom_path,
    db_name,
    db_password,
    destination_ip,
    id,
    migrate_type_id,
    migration_status,
    migration_type,
    name,
    password,
    port,
    status,
    sub_type,
    test_con_status,
    time_stamp,
    username,
    vm_source_ip,
    work_flow_id,
    db_user,
    destination_user,
    destination_password,
    resource_cpu,
    resource_memory,
    min_replicas,
    max_replicas,
    target_trigger,
    java_build_type,
    project_name,
    source_host_type,
    destination_host_type,
    destination_ssh_key,
    destination_project_id,
    destination_account_id,
    destination_project_region
) {
    return {
        type: SET_APPLICATION_MIGRATE,
        payLoad: {
            custom_path,
            db_name,
            db_password,
            destination_ip,
            id,
            migrate_type_id,
            migration_status,
            migration_type,
            name,
            password,
            port,
            status,
            sub_type,
            test_con_status,
            time_stamp,
            username,
            vm_source_ip,
            work_flow_id,
            db_user,
            destination_user,
            destination_password,
            resource_cpu,
            resource_memory,
            min_replicas,
            max_replicas,
            target_trigger,
            java_build_type,
            project_name,
            source_host_type,
            destination_host_type,
            destination_ssh_key,
            destination_project_id,
            destination_account_id,
            destination_project_region
        },
    };
}
export function setDatabaseMigrate(
    custom_path,
    db_name,
    db_password,
    destination_ip,
    id,
    migrate_type_id,
    migration_status,
    migration_type,
    name,
    password,
    port,
    status,
    sub_type,
    test_con_status,
    time_stamp,
    username,
    vm_source_ip,
    work_flow_id,
    db_user,
    destination_user,
    destination_password,
    resource_cpu,
    resource_memory,
    min_replicas,
    max_replicas,
    target_trigger,
    java_build_type,
    project_name,
    source_host_type,
    destination_host_type,
    destination_ssh_key,
    destination_project_id,
    destination_account_id,
    destination_project_region

) {
    return {
        type: SET_DATABASE_MIGRATE,
        payLoad: {
            custom_path,
            db_name,
            db_password,
            destination_ip,
            id,
            migrate_type_id,
            migration_status,
            migration_type,
            name,
            password,
            port,
            status,
            sub_type,
            test_con_status,
            time_stamp,
            username,
            vm_source_ip,
            work_flow_id,
            db_user,
            destination_user,
            destination_password,
            resource_cpu,
            resource_memory,
            min_replicas,
            max_replicas,
            target_trigger,
            java_build_type,
            project_name,
            source_host_type,
            destination_host_type,
            destination_ssh_key,
            destination_project_id,
            destination_account_id,
            destination_project_region
        },
    };
}

export function custom_path(custom_path) {
    return {
        type: CUSTOM_PATH,
        payLoad: custom_path
    }
}
export function db_name(db_name) {
    return {
        type: DB_NAME,
        payLoad: db_name
    }
}
export function db_user(db_user) {
    return {
        type: DB_USER,
        payLoad: db_user
    }
}
export function db_password(db_password) {
    return {
        type: DB_PASSWORD,
        payLoad: db_password
    }
}
export function destination_ip(destination_ip) {
    return {
        type: DESTINATION_IP,
        payLoad: destination_ip
    }
}
export function migrate_type_id(migrate_type_id) {
    return {
        type: MIGRATION_TYPE_ID,
        payLoad: migrate_type_id
    }
}
export function name(name) {
    return {
        type: NAME,
        payLoad: name
    }
}
export function password(password) {
    return {
        type: PASSWORD,
        payLoad: password
    }
}
export async function port(port, data, dispatch, dispatcher) {

    if (port.length >= 5) {
        dispatcher(isLoading())
        try {
            const response = await API.post('/connectApi/portAvailability', {
                data,
                port
            }, {}, true);
            dispatcher(isLoaded())
            dispatch(port_status(response, port))
        }
        catch (error) {
            console.log(error)
        }
        dispatcher(isLoaded())
    }
}
export function port_status(data, port) {
    return {
        type: PORT_STATUS,
        payLoad: { data: data, port: port }
    }
}

export function updatePort(port) {
    return {
        type: UPDATE_PORT,
        payLoad: port
    }
}

export function source_host_type(source_host_type) {
    return {
        type: SOURCE_HOST_TYPE,
        payLoad: source_host_type
    }
}
export function destination_host_type(destination_host_type) {
    return {
        type: DESTINATION_HOST_TYPE,
        payLoad: destination_host_type
    }
}
export function sub_type(sub_type) {
    return {
        type: SUB_TYPE,
        payLoad: sub_type
    }
}
export function vm_source_ip(vm_source_ip) {
    return {
        type: VM_SOURCE_IP,
        payLoad: vm_source_ip
    }
}
export function migrationType(migrationType) {
    return {
        type: MIGRATION_TYPE,
        payLoad: migrationType
    }
}

export function subMigrationType(subMigrationType) {
    return {
        type: SUB_MIGRATION_TYPE,
        payLoad: subMigrationType
    }
}
export function migrationData(migrationData) {
    return {
        type: MIGRATION_DATA,
        payLoad: migrationData
    }
}
export function username(username) {
    return {
        type: USER_NAME,
        payLoad: username
    }
}
export function destination_user(destination_user) {
    return {
        type: DESTINATION_USER,
        payLoad: destination_user
    }
}
export function destination_password(destination_password) {
    return {
        type: DESTINATION_PASSWORD,
        payLoad: destination_password
    }
}

export function resourceMemory(resourceMemory) {
    return {
        type: RESOURCE_MEMORY,
        payLoad: resourceMemory
    }
}
export function resourceCpu(resourceCpu) {
    return {
        type: RESOURCE_CPU,
        payLoad: resourceCpu
    }
}
export function minReplicas(minReplicas) {
    return {
        type: MIN_REPLICAS,
        payLoad: minReplicas
    }
}
export function maxReplicas(maxReplicas) {
    return {
        type: MAX_REPLICAS,
        payLoad: maxReplicas
    }
}
export function triggerTarget(triggerTarget) {
    return {
        type: TARGET_TRIGGER,
        payLoad: triggerTarget
    }
}
export function javaBuild(javaBuild) {
    return {
        type: JAVA_BUILD,
        payLoad: javaBuild
    }
}
export function project_name(project_name) {
    return {
        type: PROJECT_NAME,
        payLoad: project_name.toLowerCase()
    }
}
export function check_credential_source(status) {
    return {
        type: CHECK_CRED,
        payLoad: status
    }
}

export function check_credential_destination(status) {
    return {
        type: CHECK_CRED_DESTINATION,
        payLoad: status
    }
}

export function check_path(status) {
    return {
        type: CHECK_PATH,
        payLoad: status
    }
}

export function destination_ssh_key(destination_ssh_key) {
    return {
        type: DESTINATION_SSH_KEY,
        payLoad: destination_ssh_key
    }
}
export function destination_project_id(destination_project_id) {
    return {
        type: DESTINATION_PROJECT_ID,
        payLoad: destination_project_id
    }
}
export function destination_account_id(destination_account_id) {
    return {
        type: DESTINATION_ACCOUNT_ID,
        payLoad: destination_account_id
    }
}
export function destination_project_region(destination_project_region) {
    return {
        type: DESTINATION_PROJECT_REGION,
        payLoad: destination_project_region
    }
}
