import {
    SET_CURRENT_USER,
} from '../Actions/actions';
import Axios from 'axios';
import { clearToken } from '../LocalStorage';

//set authentication data
export const setCurrentUser = (tokenDetails) => {
    return {
        type: SET_CURRENT_USER,
        payload: tokenDetails
    };
};

export const logoutUser = (dispatch) => {
    // Remove token from local storage
    clearToken()
    // Remove auth header for future requests
    delete Axios.defaults.headers.common['x-access-token'];
    // Set current user to empty object {} which will set isAuthenticated to false
    // dispatch(setCurrentUser({}));
};