import React from 'react'

export default function RootLoader() {
    return (
        <div className="loader mt-5">
            <div className="h-100 d-flex justify-content-center">
                <div className="align-self-center">
                    <img src="./assets/img/loader/loader1.gif" alt="loader" />
                </div>
            </div>
        </div>
    )
}