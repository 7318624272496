import React, { useEffect, useState } from 'react'
import RootLoader from '../../Common/Loader/RootLoader'
import { Link } from 'react-router-dom'
import API from '../../Common/Network/API';
import { setToken } from '../../Common/LocalStorage';
import { isLoading, isLoaded } from '../../Common/Actions/loading';
import { setCurrentUser } from '../../Common/Actions';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";
import { Redirect } from 'react-router-dom'
import './index.css'
import { VERSION_DATE, VERSION_NUM } from '../../../config';


export default function Login() {

    const dispatch = useDispatch();

    const loaderStatus = useSelector(state => state.loading.status)

    const [data, setData] = useState({})
    const [redirect, setRedirect] = useState(false)
    const [message, setMessage] = useState({})

    useState(() => {
        setTimeout(() => {
            setMessage({})
        }, 5000)
    }, [message])

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    const onLogin = async (e) => {
        e.preventDefault()
        dispatch(isLoading());
        if (data.email !== "" && data.password !== "") {
            try {
                const token = Buffer.from(`${data.email}:
               ${data.password}`).toString('base64');
                const response = await API.post("authAPi/login", {
                    corpCode: data.corporateCode
                }, {
                    'Authorization': `Basic ${token}`
                });
                setToken('auth', response.accessToken)
                const tokenDetails = jwt_decode(response.accessToken);
                dispatch(setCurrentUser(tokenDetails));
                setRedirect(true)
            }
            catch (error) {
                setMessage({ 'error': error.message })
            }
        }
        dispatch(isLoaded());
    }

    if (redirect) {
        return <Redirect to="/dashboard" />
    }


    const renderContent = () => {
        if (loaderStatus) {
            return <RootLoader />
        }
        return (
            <div className="app">
                <div className="app-wrap">
                    <div className="app-contant">
                        <div className="bg-white">
                            <div className="container-fluid p-0">
                                <div className="row no-gutters">
                                    <div className="col-sm-6 col-lg-5 col-xxl-3  align-self-center order-2 order-sm-1">
                                        <div className="d-flex align-items-center h-100-vh">
                                            <div className="login p-50">
                                                <h4 className="mb-2">PEREGRINIX</h4>
                                                <span style={{ color: '#9E9FA6', textAlign: 'left', fontSize: '10px', paddingLeft: '0%' }}>Version {VERSION_NUM}  {VERSION_DATE}</span>
                                                <br />
                                                <form onSubmit={(e) => onLogin(e)} className="mt-3 mt-sm-5">
                                                    <p>Welcome back, please login to your account.</p>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="control-label">User Name*</label>
                                                                <input type="email" name="email" className="form-control" placeholder="email" onChange={e => handleChange(e)} required />
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group">
                                                                <label className="control-label">Password*</label>
                                                                <input type="password" name="password" className="form-control" placeholder="Password" onChange={e => handleChange(e)} required />
                                                            </div>
                                                        </div>
                                                        {
                                                            message && (
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="control-label error-message">{message.error}</label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        <div className="col-12">
                                                            <div className="d-block d-sm-flex  align-items-center">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                                    <label className="form-check-label" for="gridCheck">
                                                                        Remember Me
                                                                    </label>
                                                                </div>
                                                                <Link to="#" className="ml-auto">Forgot Password ?</Link>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-3">
                                                            <button className="btn btn-primary text-uppercase">Sign In</button>
                                                        </div>
                                                        <div className="col-12  mt-3">
                                                            <p>Don't have an account ?<Link to="#"> Sign Up</Link></p>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xxl-9 col-lg-7 bg-gradient o-hidden order-1 order-sm-2">
                                        <div className="row align-items-center h-100">
                                            <div className="col-7 mx-auto ">
                                                <img className="img-fluid" src="assets/img/bg/login.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    return (
        renderContent()
    );


}