import * as actions from './actions'
export const initialState = {
    pendingWorkFlow: [],
    completedWorkFlow: [],
    message: ""
};


export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.GET_PENDING_WORK_FLOWS:
            return { ...state, pendingWorkFlow: action.payload };
        case actions.GET_COMPLETED_WORK_FLOWS:
            return { ...state, completedWorkFlow: action.payload };
        case actions.MESSAGE:
            return { ...state, message: action.payload }
        default:
            return state;
    }
}