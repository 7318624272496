import * as actions from './actions';
const SelectOne = { id: 0, name: "Select One" };
const initialValue = {
    value: ""
};
export const initialState = {
    migrationType: [SelectOne],
    subMigrationType: [SelectOne],
    migrationData: "",
    custom_path: initialValue,
    db_name: initialValue,
    db_user: initialValue,
    db_password: initialValue,
    destination_ip: initialValue,
    id: initialValue,
    migrate_type_id: initialValue,
    migration_status: initialValue,
    migration_type: initialValue,
    name: initialValue,
    password: initialValue,
    port: initialValue,
    status: initialValue,
    sub_type: initialValue,
    test_con_status: initialValue,
    time_stamp: initialValue,
    username: initialValue,
    vm_source_ip: initialValue,
    work_flow_id: initialValue,
    destination_user: initialValue,
    destination_password: initialValue,
    resource_memory: {
        value: 700
    },
    resource_cpu: {
        value: 1
    },
    min_replicas: {
        value: 1
    },
    max_replicas: {
        value: 2
    },
    target_trigger: {
        value: 45
    },
    port_status: initialValue,
    java_build_type: initialValue,
    project_name: initialValue,
    source_host_type: initialValue,
    destination_host_type: initialValue,
    check_cred: initialValue,
    check_path: initialValue,
    check_cred_destination: initialValue,
    destination_ssh_key: initialValue,
    destination_project_id: initialValue,
    destination_account_id: initialValue,
    destination_project_region: initialValue,
};

export function reducer(state = initialState, action) {
    switch (action.type) {
        case actions.MIGRATION_TYPE:
            return { ...state, migrationType: state.migrationType.concat(action.payLoad) };
        case actions.SUB_MIGRATION_TYPE:
            return { ...state, subMigrationType: action.payLoad };
        case actions.MIGRATION_DATA:
            return { ...state, migrationData: action.payLoad };
        // Application Update
        case actions.SET_APPLICATION_MIGRATE:
            return {
                ...state,
                custom_path: {
                    ...state.custom_path,
                    value: action.payLoad.custom_path
                },
                db_name: {
                    ...state.db_name,
                    value: action.payLoad.db_name
                }
                ,
                db_user: {
                    ...state.db_user,
                    value: action.payLoad.db_user
                }
                ,
                db_password: {
                    ...state.db_password,
                    value: action.payLoad.db_password
                }
                ,
                destination_ip: {
                    ...state.destination_ip,
                    value: action.payLoad.destination_ip
                }
                ,
                id: {
                    ...state.id,
                    value: action.payLoad.id
                }
                ,
                migrate_type_id: {
                    ...state.migrate_type_id,
                    value: action.payLoad.migrate_type_id
                }
                ,
                migration_status: {
                    ...state.migration_status,
                    value: action.payLoad.migration_status
                }
                ,
                migration_type: {
                    ...state.migration_type,
                    value: action.payLoad.migration_type
                }
                ,
                name: {
                    ...state.name,
                    value: action.payLoad.name
                }
                ,
                password: {
                    ...state.password,
                    value: action.payLoad.password
                }
                ,
                port: {
                    ...state.port,
                    value: action.payLoad.port
                }
                ,
                status: {
                    ...state.status,
                    value: action.payLoad.status
                }
                ,
                sub_type: {
                    ...state.sub_type,
                    value: action.payLoad.sub_type
                }
                ,
                test_con_status: {
                    ...state.test_con_status,
                    value: action.payLoad.test_con_status
                }
                ,
                time_stamp: {
                    ...state.time_stamp,
                    value: action.payLoad.time_stamp
                }
                ,
                username: {
                    ...state.username,
                    value: action.payLoad.username
                }
                ,
                vm_source_ip: {
                    ...state.vm_source_ip,
                    value: action.payLoad.vm_source_ip
                }
                ,
                work_flow_id: {
                    ...state.work_flow_id,
                    value: action.payLoad.work_flow_id
                },
                destination_user: {
                    ...state.destination_user,
                    value: action.payLoad.destination_user
                },
                destination_password: {
                    ...state.destination_password,
                    value: action.payLoad.destination_password
                },
                resource_cpu: {
                    ...state.resource_cpu,
                    value: action.payLoad.resource_cpu
                },
                resource_memory: {
                    ...state.resource_memory,
                    value: action.payLoad.resource_memory
                },
                min_replicas: {
                    ...state.min_replicas,
                    value: action.payLoad.min_replicas
                },
                max_replicas: {
                    ...state.max_replicas,
                    value: action.payLoad.max_replicas
                },
                target_trigger: {
                    ...state.target_trigger,
                    value: action.payLoad.target_trigger
                },
                java_build_type: {
                    ...state.java_build_type,
                    value: action.payLoad.java_build_type
                },
                project_name: {
                    ...state.project_name,
                    value: action.payLoad.project_name
                },
                source_host_type: {
                    ...state.source_host_type,
                    value: action.payLoad.source_host_type
                },
                destination_host_type: {
                    ...state.destination_host_type,
                    value: action.payLoad.destination_host_type
                },
                destination_ssh_key: {
                    ...state.destination_ssh_key,
                    value: action.payLoad.destination_ssh_key
                },
                destination_project_id: {
                    ...state.destination_project_id,
                    value: action.payLoad.destination_project_id
                },
                destination_account_id: {
                    ...state.destination_project_id,
                    value: action.payLoad.destination_account_id
                },
                destination_project_region: {
                    ...state.destination_project_id,
                    value: action.payLoad.destination_project_region
                }

            }
        // Database Update
        case actions.SET_DATABASE_MIGRATE:
            return {
                ...state,
                custom_path: {
                    ...state.custom_path,
                    value: action.payLoad.custom_path
                },
                db_name: {
                    ...state.db_name,
                    value: action.payLoad.db_name
                }
                ,
                db_user: {
                    ...state.db_user,
                    value: action.payLoad.db_user
                }
                ,
                db_password: {
                    ...state.db_password,
                    value: action.payLoad.db_password
                }
                ,
                destination_ip: {
                    ...state.destination_ip,
                    value: action.payLoad.destination_ip
                }
                ,
                id: {
                    ...state.id,
                    value: action.payLoad.id
                }
                ,
                migrate_type_id: {
                    ...state.migrate_type_id,
                    value: action.payLoad.migrate_type_id
                }
                ,
                migration_status: {
                    ...state.migration_status,
                    value: action.payLoad.migration_status
                }
                ,
                migration_type: {
                    ...state.migration_type,
                    value: action.payLoad.migration_type
                }
                ,
                name: {
                    ...state.name,
                    value: action.payLoad.name
                }
                ,
                password: {
                    ...state.password,
                    value: action.payLoad.password
                }
                ,
                port: {
                    ...state.port,
                    value: action.payLoad.port
                }
                ,
                status: {
                    ...state.status,
                    value: action.payLoad.status
                }
                ,
                sub_type: {
                    ...state.sub_type,
                    value: action.payLoad.sub_type
                }
                ,
                test_con_status: {
                    ...state.test_con_status,
                    value: action.payLoad.test_con_status
                }
                ,
                time_stamp: {
                    ...state.time_stamp,
                    value: action.payLoad.time_stamp
                }
                ,
                username: {
                    ...state.username,
                    value: action.payLoad.username
                }
                ,
                vm_source_ip: {
                    ...state.vm_source_ip,
                    value: action.payLoad.vm_source_ip
                }
                ,
                work_flow_id: {
                    ...state.work_flow_id,
                    value: action.payLoad.work_flow_id
                },
                destination_user: {
                    ...state.destination_user,
                    value: action.payLoad.destination_user
                },
                destination_password: {
                    ...state.destination_password,
                    value: action.payLoad.destination_password
                },
                resource_cpu: {
                    ...state.resource_cpu,
                    value: action.payLoad.resource_cpu
                },
                resource_memory: {
                    ...state.resource_memory,
                    value: action.payLoad.resource_memory
                },
                min_replicas: {
                    ...state.min_replicas,
                    value: action.payLoad.min_replicas
                },
                max_replicas: {
                    ...state.max_replicas,
                    value: action.payLoad.max_replicas
                },
                target_trigger: {
                    ...state.target_trigger,
                    value: action.payLoad.target_trigger
                },
                source_host_type: {
                    ...state.source_host_type,
                    value: action.payLoad.source_host_type
                },
                destination_host_type: {
                    ...state.destination_host_type,
                    value: action.payLoad.destination_host_type
                },
                project_name: {
                    ...state.project_name,
                    value: action.payLoad.project_name
                },
                destination_ssh_key: {
                    ...state.destination_ssh_key,
                    value: action.payLoad.destination_ssh_key
                },
                destination_project_id: {
                    ...state.destination_project_id,
                    value: action.payLoad.destination_project_id
                },
                destination_account_id: {
                    ...state.destination_project_id,
                    value: action.payLoad.destination_account_id
                },
                destination_project_region: {
                    ...state.destination_project_id,
                    value: action.payLoad.destination_project_region
                }



            }
        case actions.VM_SOURCE_IP: return {
            ...state,
            vm_source_ip: {
                ...state.vm_source_ip,
                value: action.payLoad,
            },
        };
        case actions.CUSTOM_PATH: return {
            ...state,
            custom_path: {
                ...state.custom_path,
                value: action.payLoad,
            },
        };
        case actions.DB_NAME: return {
            ...state,
            db_name: {
                ...state.db_name,
                value: action.payLoad,
            },
        };
        case actions.DB_USER: return {
            ...state,
            db_user: {
                ...state.db_user,
                value: action.payLoad,
            },
        };
        case actions.DB_PASSWORD:
            return {
                ...state,
                db_password: {
                    ...state.db_password,
                    value: action.payLoad,
                },
            };
        case actions.NAME:
            return {
                ...state,
                name: {
                    ...state.name,
                    value: action.payLoad,
                },
            };
        case actions.PASSWORD:
            return {
                ...state,
                password: {
                    ...state.password,
                    value: action.payLoad,
                },
            };
        case actions.MIGRATION_TYPE_ID:
            return {
                ...state,
                migrate_type_id: {
                    ...state.migrate_type_id,
                    value: action.payLoad,
                },
            };

        case actions.DESTINATION_IP: return {
            ...state,
            destination_ip: {
                ...state.destination_ip,
                value: action.payLoad,
            },
        };
        case actions.UPDATE_PORT:
            return {
                ...state,
                port: {
                    ...state.port,
                    value: action.payLoad
                }
            };
        case actions.SUB_TYPE:
            return {
                ...state,
                sub_type: {
                    ...state.sub_type,
                    value: action.payLoad,
                },
            }
        case actions.USER_NAME:
            return {
                ...state,
                username: {
                    ...state.username,
                    value: action.payLoad,
                },
            }
        case actions.DESTINATION_USER:
            return {
                ...state,
                destination_user: {
                    ...state.destination_user,
                    value: action.payLoad
                }
            }
        case actions.DESTINATION_PASSWORD:
            return {
                ...state,
                destination_password: {
                    ...state.destination_password,
                    value: action.payLoad
                }
            }
        case actions.RESOURCE_MEMORY:
            return {
                ...state,
                resource_memory: {
                    ...state.resource_memory,
                    value: action.payLoad
                }
            }
        case actions.RESOURCE_CPU:
            return {
                ...state,
                resource_cpu: {
                    ...state.resource_cpu,
                    value: action.payLoad
                }
            }
        case actions.MIN_REPLICAS:
            return {
                ...state,
                min_replicas: {
                    ...state.min_replicas,
                    value: action.payLoad
                }
            }
        case actions.MAX_REPLICAS:
            return {
                ...state,
                max_replicas: {
                    ...state.max_replicas,
                    value: action.payLoad
                }
            }
        case actions.TARGET_TRIGGER:
            return {
                ...state,
                target_trigger: {
                    ...state.target_trigger,
                    value: action.payLoad
                }
            }
        case actions.PORT_STATUS:
            return {
                ...state,
                port_status: {
                    ...state.port_status,
                    value: action.payLoad.data
                },
                port: {
                    ...state.port,
                    value: action.payLoad.port
                }
            }
        case actions.JAVA_BUILD:
            return {
                ...state,
                java_build_type: {
                    ...state.java_build_type,
                    value: action.payLoad
                }
            }
        case actions.PROJECT_NAME:
            return {
                ...state,
                project_name: {
                    ...state.project_name,
                    value: action.payLoad
                }
            }
        case actions.SOURCE_HOST_TYPE:
            return {
                ...state,
                source_host_type: {
                    ...state.source_host_type,
                    value: action.payLoad
                }
            }
        case actions.DESTINATION_HOST_TYPE:
            return {
                ...state,
                destination_host_type: {
                    ...state.destination_host_type,
                    value: action.payLoad
                }
            }

        case actions.CHECK_CRED:
            return {
                ...state,
                check_cred: {
                    ...state.check_cred,
                    value: action.payLoad
                }
            }
        case actions.CHECK_PATH:
            return {
                ...state,
                check_path: {
                    ...state.check_path,
                    value: action.payLoad
                }
            }
        case actions.CHECK_CRED_DESTINATION:
            return {
                ...state,
                check_cred_destination: {
                    ...state.check_cred_destination,
                    value: action.payLoad
                }
            }
        case actions.DESTINATION_SSH_KEY:
            return {
                ...state,
                destination_ssh_key: {
                    ...state.destination_ssh_key,
                    value: action.payLoad
                }
            }
        case actions.DESTINATION_PROJECT_ID:
            return {
                ...state,
                destination_project_id: {
                    ...state.destination_project_id,
                    value: action.payLoad
                }
            }
        case actions.DESTINATION_ACCOUNT_ID:
            return {
                ...state,
                destination_account_id: {
                    ...state.destination_account_id,
                    value: action.payLoad
                }
            }
        case actions.DESTINATION_PROJECT_REGION:
            return {
                ...state,
                destination_project_region: {
                    ...state.destination_project_region,
                    value: action.payLoad
                }
            }
        default:
            return state;
    }
}