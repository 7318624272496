import API from "../../Common/Network/API";
import { isLoading, isLoaded } from "../../Common/Actions/loading";
import store from "../../Common/Store";
import { getPendingWorkFlow, getCompletedWorkFlow, Message } from './actions'

export async function addWorkFlow(dispatch, data) {
    store.dispatch(isLoading());
    try {
        var response = await API.post("/workFlow/addWorkFlow", { data }, {}, true);
        dispatch(Message(response.message))
        getPendingWorkFlow(dispatch)
        getCompletedWorkFlow(dispatch)
 
    } catch (error) {
        dispatch(Message(error.message))
    }
    store.dispatch(isLoaded());
}